import { SubmitHandler, useForm } from 'react-hook-form';
import { useMemo, ChangeEvent, useState, useEffect } from 'react';
import { useCurrentUser, useGetMyPreferences } from '@bpm-web-app/swr-hooks';
import { User } from '@bpm-web-app/api-client';
import ReactModal from 'react-modal';
import { useApiErrorHandler, showToast, useUserSettings, getSignupRedirectLinkAccountPlans } from '@bpm-web-app/utils';
import router from 'next/router';
import SettingsSwitch from './settings-switch/settings-switch';
import styles from './account-settings.module.css';
import PinModal, { PinState } from './pin-modal/pin-modal';

interface Inputs {
    app_recommendations: boolean;
    artist_updates: boolean;
    collection_updates: boolean;
    contest_and_giveaways: boolean;
    event_workshop_invites: boolean;
    promotional: boolean;
    recommendations: boolean;
    weekly_newsletter: boolean;
    parentalControl?: boolean;
    pinCode?: number;
    create_label_updates: boolean;
    create_recommendations: boolean;
    ap_app_submission: boolean
    ap_email_submission: boolean
    ap_app_playlist: boolean
    ap_email_playlist: boolean
    ap_app_promotion: boolean
    ap_email_promotion: boolean
    ap_app_competition: boolean
    ap_email_competition: boolean
}

interface AccountSettingsProps {
    isCreate?: boolean;
    isArtistPlatform?: boolean;
}

export function AccountSettings({ isCreate, isArtistPlatform }: AccountSettingsProps) {
    const { data: userData, isLoading: isLoadingUser, mutate: mutateUser } = useCurrentUser();
    const { data: preferencesData, isLoading: isLoadingPreferences, mutate: mutatePreferences } = useGetMyPreferences();
    const errorHandler = useApiErrorHandler(isCreate);

    const [isPinModalVisible, setIsPinModalVisible] = useState(false);
    const [isDisablePinModal, setIsDisablePinModal] = useState(false);
    const [isAllEnable, setIsAllEnable] = useState(false);
    const [typePin, setTypePin] = useState(null);

    const pageText = useMemo(() => {
        const sections = [
            {
                sectionTitle: 'App Notifications',
                info: [
                    {
                        key: 'collection_updates',
                        title: 'Playlist & Curated Set Updates',
                        subTitle: 'A playlist or Curated you follow is updated',
                        isVisible: !isCreate && !isArtistPlatform
                    },
                    {
                        key: 'artist_updates',
                        title: 'Artist Updates',
                        subTitle: 'Get notifications about your favorite artists and their new releases.',
                        isVisible: !isCreate && !isArtistPlatform
                    },
                    {
                        key: 'app_recommendations',
                        title: 'Recommendations',
                        subTitle: 'Recommendations we find that we think you’ll like',
                        isVisible: !isCreate && !isArtistPlatform
                    },
                    {
                        key: 'create_label_updates',
                        title: 'Label Updates',
                        subTitle: 'Get notifications about your favorite labels and their new releases.',
                        isVisible: !!isCreate && !isArtistPlatform
                    },
                    {
                        key: 'create_recommendations',
                        title: 'Recommendations',
                        subTitle: 'Recommendations we find that we think you’ll like.',
                        isVisible: !!isCreate && !isArtistPlatform
                    },
                    {
                        key: 'ap_app_submission',
                        title: 'Track Submission Accepted/Rejected',
                        subTitle: 'A track you submitted has been accepted/rejected',
                        isVisible: isArtistPlatform
                    },
                    {
                        key: 'ap_app_playlist',
                        title: 'Track Added to Playlist',
                        subTitle: 'One of your tracks has been added to a BPM Supreme playlist',
                        isVisible: isArtistPlatform
                    },
                    {
                        key: 'ap_app_promotion',
                        title: 'Promotional Events',
                        subTitle: 'Promos and events specifically for White Label Artists',
                        isVisible: isArtistPlatform
                    },
                    {
                        key: 'ap_app_promotion',
                        title: 'Competitions',
                        subTitle: 'Updates on competitions that are relevant to you',
                        isVisible: isArtistPlatform
                    },
                ],
            },
            {
                sectionTitle: 'Email Notification Preferences',
                info: [
                    {
                        key: 'weekly_newsletter',
                        title: 'BPM News',
                        subTitle: 'Get notified about the latest DJ news.',
                        isVisible: !isArtistPlatform
                    },
                    {
                        key: 'contest_and_giveaways',
                        title: 'Competitions',
                        subTitle: 'Get updates on current competitions and challenges.',
                        isVisible: !isArtistPlatform
                    },
                    {
                        key: 'event_workshop_invites',
                        title: 'Event & Workshop Invites',
                        subTitle: 'Events & workshops invites that we think you’ll like',
                        isVisible: !isArtistPlatform
                    },
                    {
                        key: 'promotional',
                        title: 'Events & Promo',
                        subTitle: 'Learn about special offers and upcoming events.',
                        isVisible: !isArtistPlatform
                    },
                    {
                        key: 'recommendations',
                        title: 'Recommended Music',
                        subTitle: 'Get music we think you’ll like based on your activity.',
                        isVisible: !isArtistPlatform
                    },
                    {
                        key: 'ap_email_submission',
                        title: 'Track Submission Accepted/Rejected',
                        subTitle: 'A track you submitted has been accepted/rejected',
                        isVisible: isArtistPlatform
                    },
                    {
                        key: 'ap_email_playlist',
                        title: 'Track Added to Playlist',
                        subTitle: 'One of your tracks has been added to a BPM Supreme playlist',
                        isVisible: isArtistPlatform
                    },
                    {
                        key: 'ap_email_promotion',
                        title: 'Promotional Events',
                        subTitle: 'Promos and events specifically for White Label Artists',
                        isVisible: isArtistPlatform
                    },
                    {
                        key: 'ap_email_promotion',
                        title: 'Competitions',
                        subTitle: 'Updates on competitions that are relevant to you',
                        isVisible: isArtistPlatform
                    },
                ],
                isVisible: true
            },
            {
                sectionTitle: 'Parental Controls',
                info: [
                    {
                        key: 'parentalControl',
                        title: 'Remove Explicit Content',
                        subTitle: 'Toggle on to remove music tagged as explicit.',
                        isVisible: true
                    },
                ],
                isVisible: !isCreate && !isArtistPlatform,
            },
        ];
        return sections
            .filter((section) => section.isVisible === undefined || section.isVisible)
            .map((section) => ({
                ...section,
                info: section.info.filter((setting) => setting.isVisible === undefined || setting.isVisible),
            }));
    }, [isCreate, isArtistPlatform]);

    const formData: Inputs = useMemo(() => {
        if (!userData && !preferencesData) return null;
        return {
            app_recommendations: preferencesData?.data?.app_recommendations,
            artist_updates: preferencesData?.data?.artist_updates,
            collection_updates: preferencesData?.data?.collection_updates,
            contest_and_giveaways: preferencesData?.data?.contest_and_giveaways,
            event_workshop_invites: preferencesData?.data?.event_workshop_invites,
            promotional: preferencesData?.data?.promotional,
            recommendations: preferencesData?.data?.recommendations,
            weekly_newsletter: preferencesData?.data?.weekly_newsletter,
            create_label_updates: preferencesData?.data?.create_label_updates,
            create_recommendations: preferencesData?.data?.create_recommendations,
            ap_app_competition: preferencesData?.data?.ap_app_competition,
            ap_app_playlist: preferencesData?.data?.ap_app_playlist,
            ap_app_promotion: preferencesData?.data?.ap_app_promotion,
            ap_app_submission: preferencesData?.data?.ap_app_submission,
            ap_email_competition: preferencesData?.data?.ap_email_competition,
            ap_email_playlist: preferencesData?.data?.ap_email_playlist,
            ap_email_promotion: preferencesData?.data?.ap_email_promotion,
            ap_email_submission: preferencesData?.data?.ap_email_submission,
            ...((!isCreate && !isArtistPlatform) ? {
                parentalControl: userData?.data?.user?.disable_explicit_content,
            } : {}),
        };
    }, [preferencesData, userData, isCreate, isArtistPlatform]);

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        watch,
        formState: { isSubmitting, isDirty },
    } = useForm<Inputs>();

    const parentalControlCheck = watch('parentalControl');
    useEffect(() => {
        reset(formData);
    }, [reset, formData]);

    const { isAnonymous } = useUserSettings();

    useEffect(() => {
        if (isAnonymous) {
            router.replace(getSignupRedirectLinkAccountPlans());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAnonymous]);

    useEffect(() => {
        if (parentalControlCheck && !formData?.parentalControl) {
            setIsDisablePinModal(false);
            setIsPinModalVisible(true);
        }
        if (parentalControlCheck === false && formData?.parentalControl) {
            setIsDisablePinModal(true);
            setIsPinModalVisible(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parentalControlCheck]);

    useEffect(() => {
        if (formData) {
            setIsAllEnable(Object.entries(formData).every(([_, value]) => value === true));
        } else {
            setIsAllEnable(false);
        }
    }, [formData]);

    // TODO: replace with loading status
    if (!formData || isLoadingPreferences || isLoadingUser) return null;

    const handleMainSwitch = (e: ChangeEvent<HTMLInputElement>) => {
        setIsAllEnable(e.target.checked);
        Object.entries(formData).forEach(([name]) => name !== 'pinCode' && setValue(name as keyof Inputs, e.target.checked, { shouldDirty: true }));
    };

    const onSubmit: SubmitHandler<Inputs> = async (formFields) => {
        try {
            if (isDirty) {
                await mutatePreferences(
                    User.setMyPreferences({
                        app_recommendations: formFields.app_recommendations,
                        artist_updates: formFields.artist_updates,
                        collection_updates: formFields.collection_updates,
                        contest_and_giveaways: formFields.contest_and_giveaways,
                        event_workshop_invites: formFields.event_workshop_invites,
                        promotional: formFields.promotional,
                        recommendations: formFields.recommendations,
                        weekly_newsletter: formFields.weekly_newsletter,
                        create_label_updates: formFields.create_label_updates,
                        create_recommendations: formFields.create_recommendations,
                        ap_app_competition: formFields.ap_app_competition,
                        ap_app_playlist: formFields.ap_app_playlist,
                        ap_app_promotion: formFields.ap_app_promotion,
                        ap_app_submission: formFields.ap_app_submission,
                        ap_email_competition: formFields.ap_email_competition,
                        ap_email_playlist: formFields.ap_email_playlist,
                        ap_email_promotion: formFields.ap_email_promotion,
                        ap_email_submission: formFields.ap_email_submission
                    })
                );

                if (formFields.parentalControl !== formData.parentalControl) {
                    if (typePin === PinState.CREATE) {
                        await User.disableExplicitContent({ code: formFields.pinCode.toString() });
                    }
                    if (typePin === PinState.REMOVE) {
                        await User.enableExplicitContent({ code: formFields.pinCode.toString() });
                    }
                    await mutateUser();
                }
                showToast({ type: 'success', message: 'Settings saved.' });
            }
        } catch (error: any) {
            reset(formData);
            errorHandler({ error });
        }
    };

    return (
        <>
            <div className={styles['account-settings__header']}>
                <h2>Settings</h2>
                <div className={styles['account-settings__header-switch']}>
                    <span>{isAllEnable ? 'Disable All' : 'Enable All'}</span>
                    <SettingsSwitch isChecked={isAllEnable} onChange={(e) => handleMainSwitch(e)} />
                </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className={styles['account-settings__content']}>
                {pageText.map(({ sectionTitle, info }) => (
                    <div className={styles['account-settings__form-container']} key={sectionTitle}>
                        <h4>{sectionTitle}</h4>
                        {info.map(({ key, title, subTitle }) => (
                            <div key={key} className={styles['account-settings__row']}>
                                <div>
                                    <h5 className={styles['account-settings__title']}>{title}</h5>
                                    <span className={styles['account-settings__sub-title']}>{subTitle}</span>
                                </div>
                                <SettingsSwitch register={register(key as keyof Inputs)} />
                            </div>
                        ))}
                    </div>
                ))}
                <button disabled={isSubmitting || !isDirty} className="account__button" aria-label="submit form" type="submit">
                    {' '}
                    Save Changes{' '}
                </button>
            </form>
            <ReactModal
                isOpen={isPinModalVisible}
                className="react-modal__content"
                overlayClassName="react-modal__overlay"
                onRequestClose={() => setIsPinModalVisible(false)}
                shouldCloseOnOverlayClick={false}
            >
                <PinModal onModalClose={() => setIsPinModalVisible(false)} setValue={setValue} setTypePin={setTypePin} isDisable={isDisablePinModal} />
            </ReactModal>
        </>
    );
}

export default AccountSettings;
