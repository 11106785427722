import { SortByQueryOptions } from '@bpm-web-app/create-api-sdk';
import { useGetFavoritedCreateCuratedSets, useGetFavoritedLabels, useGetFavoritedPresets, useGetFavoritedSoundPackages, useGetFavoritedSounds } from '@bpm-web-app/swr-hooks';
import router from 'next/router';
import { useMemo } from 'react';
import classNames from 'classnames';
import { useCreateFilterParams, useViewport } from '@bpm-web-app/utils';
import BreakpointView from '../../shared/ui/breakpoint-view/breakpoint-view';
import { FavoritesDesktopCreate } from './favorites-desktop/favorites-desktop-create';
import styles from './favorites.module.css';
import Filters from '../../filters/filters';
import FavoritesMobileCreate from './favorites-mobile/favorites-mobile.create';
import { GhostComponent, PrimaryPageTitle } from '../../shared';

const PACKS_SOUNDS_DESKTOP_ITEMS = 10;
const LABELS_DESKTOP_ITEMS = 20;

export function FavoritesCreate() {
    const { sortBy } = router.query;
    const { isMobile } = useViewport();

    const query = useCreateFilterParams({ sort_by: sortBy as unknown as SortByQueryOptions }); // ToDo sorting

    const { data: favoritesSoundPacks, isLoading: isLoadingFavoritesSoundPacks } = useGetFavoritedSoundPackages(query);
    const { data: favoritesSounds, isLoading: isLoadingFavoritesSounds } = useGetFavoritedSounds(query);
    const { data: favoritesPresets, isLoading: isLoadingFavoritesPresets } = useGetFavoritedPresets({ ...query, limit: 5 });
    const { data: favoritesCuratedSets, isLoading: isLoadingFavoritesCuratedSets } = useGetFavoritedCreateCuratedSets(query);
    const { data: favoritesLabels, isLoading: isLoadingFavoritesLabels } = useGetFavoritedLabels();

    const headerSection = useMemo(
        () => (
            <div className={styles['favorites__header']}>
                <PrimaryPageTitle title="Favorites" noPadding />
                <Filters platform="create" showOnMobile />
            </div>
        ),
        []
    );

    const isEverythingLoaded = useMemo(() => {
        if (!isLoadingFavoritesSoundPacks && !isLoadingFavoritesSounds && !isLoadingFavoritesPresets && !isLoadingFavoritesCuratedSets && !isLoadingFavoritesLabels) {
            return true;
        }
        return false;
    }, [isLoadingFavoritesCuratedSets, isLoadingFavoritesLabels, isLoadingFavoritesPresets, isLoadingFavoritesSoundPacks, isLoadingFavoritesSounds]);

    if (!isEverythingLoaded) {
        if (isMobile) {
            return (
                <>
                    <div className="spacing--top" />
                    <GhostComponent type="secondary-title" />
                    <div className="spacing--top" />

                    <GhostComponent type="track-list" elementsCount={10} />
                </>
            );
        }

        return (
            <>
                <div className="spacing--top" />
                <GhostComponent type="primary-title" />
                <div className="spacing--top-double" />
                <GhostComponent type="secondary-title" />
                <GhostComponent type="cards" elementsCount={10} linesWidthArray={[90, 60]} />

                <GhostComponent type="secondary-title" />

                <GhostComponent type="track-list" elementsCount={10} isCreate />
                {[1, 2, 3].map(() => {
                    return (
                        <>
                            <GhostComponent type="secondary-title" />

                            <GhostComponent type="cards" elementsCount={10} linesWidthArray={[90, 60]} />
                        </>
                    );
                })}
            </>
        );
    }

    return (
        // eslint-disable-next-line @typescript-eslint/dot-notation
        <div className={classNames(styles['favorites'], 'spacing__window')}>
            {headerSection}
            <BreakpointView
                desktopChildren={
                    <FavoritesDesktopCreate
                        soundPacks={favoritesSoundPacks?.data?.slice(0, PACKS_SOUNDS_DESKTOP_ITEMS)}
                        soundPacksCount={favoritesSoundPacks?.pagination?.total}
                        sounds={favoritesSounds?.data?.slice(0, PACKS_SOUNDS_DESKTOP_ITEMS)}
                        soundsCount={favoritesSounds?.pagination?.total}
                        presets={favoritesPresets?.data}
                        presetCount={favoritesPresets?.pagination?.total}
                        curatedSets={favoritesCuratedSets?.data?.slice(0, PACKS_SOUNDS_DESKTOP_ITEMS)}
                        curatedSetsCount={favoritesCuratedSets?.pagination?.total}
                        labels={favoritesLabels?.data?.slice(0, LABELS_DESKTOP_ITEMS)}
                        labelsCount={favoritesLabels?.data?.length}
                    />
                }
                mobileChildren={
                    <FavoritesMobileCreate
                        soundPacks={favoritesSoundPacks?.data}
                        soundPacksCount={favoritesSoundPacks?.pagination?.total}
                        sounds={favoritesSounds?.data}
                        presets={favoritesPresets?.data}
                        presetCount={favoritesPresets?.pagination?.total}
                        soundsCount={favoritesSounds?.pagination?.total}
                        curatedSets={favoritesCuratedSets?.data}
                        curatedSetsCount={favoritesCuratedSets?.pagination?.total}
                        labels={favoritesLabels?.data}
                        labelsCount={favoritesLabels?.data?.length}
                    />
                }
            />
        </div>
    );
}
export default FavoritesCreate;
