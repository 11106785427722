import { appendQueryParams, convertToPluralIfNeeded, createAppRoutes } from '@bpm-web-app/utils';
import { Label } from '@bpm-web-app/create-api-sdk';
import { useCreateLike } from '@bpm-web-app/swr-hooks';
import { useMemo } from 'react';
import { CardCreate } from '../card-create';
import { CardDescriptionCreate } from '../card-description-create/card-description-create';

export interface LabelCardCreateProps {
    item: Label;
    items: Label[];
}

export function LabelCardCreate({
    item,
    items,
}: LabelCardCreateProps) {
    const { isLiked, likeDislike } = useCreateLike('label');

    const packDescription = useMemo(() => {
        return item ? (
            <CardDescriptionCreate
                descriptionLabel={convertToPluralIfNeeded(item.soundpackage_count || 0, 'Pack')}
                genres={item.top_genres}
            />
        ) : null;
    }, [item]);

    return (
        <CardCreate
            contentType="label"
            cardSize="small"
            key={item.id}
            id={item.id}
            title={item.name}
            imageUrl={appendQueryParams(item.logo_url, { key: 'dw', value: 160 })}
            imageUrl2x={appendQueryParams(item.logo_url, { key: 'dw', value: 320 })}
            description={packDescription}
            onFavorite={likeDislike}
            isFavorite={isLiked(item.id)}
            link={createAppRoutes.labelDetail(item.name)}
            items={items}
        />
    );
}
