import { Media, Notifications, Playlist as PlaylistsApi, UserPlaylist as UserPlaylistApi } from '@bpm-web-app/api-client';
import { UserPlaylistWithAlbum, PlaylistWithAlbum, Album as DownloadAlbum } from '@bpm-web-app/download-api-sdk';
import { UserPlaylistWithMedia, PlaylistWithMedia, MediaWithAlbum } from '@bpm-web-app/stream-api-sdk';
import { Notification, NotificationTypeIdentifier } from '@bpm-web-app/supreme-api-sdk';
import { useNotifications, useReadNotifications } from '@bpm-web-app/swr-hooks';
import { downloadAlbumWithMediaToQueueItem, formatDateToString, getPlatformLinkUsingRouter, State, streamMediaWithAlbumToQueueItem, useApiErrorHandler, useHubSwitch, usePlayerState } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { useLibraryTabs } from 'libs/utils/src/lib/library-tabs.context';

import { SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import ChevronLeft from '../../assets/icons/chevron-left.svg';
import PageSwitcher from '../page-switcher/page-switcher';
import { usePlayer } from '../player-context';
import { CreateThreeDotsSheet, CreateThreeDotsSheetContext, EmptyState, ThreeDotsSheet, ThreeDotsSheetContext } from '../shared';
import BPMIcons from '../shared/bpm-icons/bpm-icons';
import NotificationSection from './user-notifications-section';
import styles from './user-notifications.module.css';

interface TimelineNotifications {
    today: Notification[];
    thisWeek: Notification[];
    lastWeek: Notification[];
    earlier: Notification[];
}

interface UserNotificationProps {
    platform: Notification.PlatformEnum;
    onClose: () => void;
    isVisible?: boolean;
    isMobile?: boolean;
}

export function UserNotifications({ platform, onClose, isVisible, isMobile = false }: UserNotificationProps) {
    const { data, error, isLoading, mutate } = useNotifications([platform]);
    const errorHandler = useApiErrorHandler();
    const [allNotifications, setAllNotifications] = useState<TimelineNotifications>();
    const [releasesNotifications, setReleasesNotifications] = useState<TimelineNotifications>();
    const [playlistNotifications, setPlaylistNotifications] = useState<TimelineNotifications>();
    const [systemNotifications, setSystemNotifications] = useState<TimelineNotifications>();
    const readNotifications = useReadNotifications([platform]);
    const router = useRouter();

    const notifications = useMemo(() => data?.data || [], [data]);

    const playerState = usePlayerState();
    const { isDownload, isCreate } = useHubSwitch();
    const { libraryProperty } = useLibraryTabs();
    const { setQueue, silenceWorkaround, togglePlayPause, originalListDetails } = usePlayer();
    const resource = null;

    const isPlaying = useCallback((notification: Notification) => {
        if (playerState !== State.Playing) {
            return false;
        }

        if (platform === Notification.PlatformEnum.Create) {
            // Handle isPlaying on Create
            // MARK: we currently don't play sounds from here.
            return false;
        }
        // Handle isPlaying on Supreme
        switch (notification.type_identifier) {
            case NotificationTypeIdentifier.NewPlaylistUpdate:
            case NotificationTypeIdentifier.NewRecommendation:
                return originalListDetails?.identifier === notification.associated_id;
            case NotificationTypeIdentifier.NewArtistRelease:
                if (notification.link?.split('/')[3]) {
                    const slug = notification.link?.split('/')[3];
                    return originalListDetails?.identifier === slug && originalListDetails?.resource === 'artist';
                }
                return false;
            default:
                return false;
        }
    }, [playerState, platform, originalListDetails?.identifier, originalListDetails?.resource]);

    const handlePlayMedia = useCallback(
        async (notification: Notification) => {
            silenceWorkaround();
            const id = notification.associated_id;
            if (platform === Notification.PlatformEnum.Create) {
                // Handle Play on Create
                // MARK: we currently don't play sounds from here.
            } else {
                // Handle Play on Supreme
                switch (notification.type_identifier) {
                    case NotificationTypeIdentifier.NewRecommendation:
                        try {
                            silenceWorkaround();
                            const { data: media } = await UserPlaylistApi.getUserPlaylistDetail(isDownload, `${id}`);

                            const queueMedia = isDownload
                                ? (media as UserPlaylistWithAlbum).albums.map((curatedSetAlbum) => downloadAlbumWithMediaToQueueItem(curatedSetAlbum))
                                : (media as UserPlaylistWithMedia).media.map((curatedSetMedia) => streamMediaWithAlbumToQueueItem(curatedSetMedia));

                            if (queueMedia) setQueue(queueMedia, 0, { identifier: `${id}`, resource });

                            break;
                        } catch (err) {
                            errorHandler({ error: err });
                            break;
                        }
                    case NotificationTypeIdentifier.NewPlaylistUpdate:
                        try {
                            silenceWorkaround();
                            const { data: media } = await PlaylistsApi.getPlaylistDetail(isDownload, Number(id));

                            const queueMedia = isDownload
                                ? (media as PlaylistWithAlbum).albums.map((curatedSetAlbum) => downloadAlbumWithMediaToQueueItem(curatedSetAlbum))
                                : (media as PlaylistWithMedia).media.map((curatedSetMedia) => streamMediaWithAlbumToQueueItem(curatedSetMedia));
                            setQueue(queueMedia, 0, { identifier: `${id}`, resource });

                            break;
                        } catch (err) {
                            errorHandler({ error: err });
                            break;
                        }
                    case NotificationTypeIdentifier.NewArtistRelease:
                        try {
                            if (notification.associated_type === 'Artist') {
                                const slug = notification.link?.split('/')[3];

                                if (slug) {
                                    silenceWorkaround();
                                    const { data: media } = isDownload ? await Media.searchAlbum(true, {
                                        artist: slug,
                                        library: libraryProperty,
                                        limit: 20
                                    }) : await Media.searchMedia({
                                        artist: slug,
                                        library: libraryProperty,
                                        limit: 20
                                    });
                                    if (!media) return;
                                    const queueMedia = isDownload
                                        ? (media as DownloadAlbum[]).map((album) => downloadAlbumWithMediaToQueueItem(album))
                                        : (media as MediaWithAlbum[]).map((currentMedia) => streamMediaWithAlbumToQueueItem(currentMedia));

                                    setQueue(queueMedia, 0, { identifier: slug, resource: 'artist' });
                                }
                            } else if (notification.associated_type === 'Album') {
                                if (isDownload) {
                                    const { data: album } = await Media.getAlbum(true, +id!);
                                    const queueMedia = downloadAlbumWithMediaToQueueItem(album as DownloadAlbum);
                                    setQueue([queueMedia], 0, { resource: 'album', identifier: id! });
                                }
                            }

                            break;
                        } catch (err) {
                            errorHandler({ error: err });
                            break;
                        }

                    default:
                        break;
                }
            }
        }, [errorHandler, isDownload, libraryProperty, platform, setQueue, silenceWorkaround]);

    /**
     * If @param id is null it will delete all otherwise the corresponding id
     */
    const handleDelete = useCallback(
        async (id: string | null) => {
            try {
                const nextNotifications = id ? notifications.filter((notification) => notification.id !== id) : [];

                mutate({ data: nextNotifications }, { revalidate: false });
                if (id) {
                    await Notifications.deleteNotification(id);
                } else {
                    await Notifications.deleteNotifications([platform]);
                }
            } catch (err) {
                errorHandler({ error: err });
                mutate(undefined, { revalidate: true });
            }
        },
        [errorHandler, mutate, notifications, platform]
    );

    const handleClearAll = useCallback(async () => {
        handleDelete(null);
    }, [handleDelete]);

    const handleDeleteNotification = useCallback(
        async (id: string) => {
            handleDelete(id);
        },
        [handleDelete]
    );

    const markAsRead = useRef(false);

    useEffect(() => {
        if (isVisible && data?.data?.some((notification) => !notification.read)) {
            markAsRead.current = true;
            readNotifications();
        } else if (!isVisible && markAsRead.current) {
            markAsRead.current = false;
            if (data?.data?.some((notification) => !notification.read)) { mutate({ data: data?.data?.map((notification) => ({ ...notification, read: true })) }, { revalidate: false }); }
        }
    }, [data, mutate, readNotifications, isVisible]);

    const isDateInThisWeek = (date: Date) => {
        const todayObj = new Date();
        const todayDate = todayObj.getDate();
        const todayDay = todayObj.getDay();

        // get first date of this week
        const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));

        // get last date of this week
        const lastDayOfWeek = new Date(firstDayOfWeek);
        lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

        // if date is equal or within the first and last dates of the week
        return date >= firstDayOfWeek && date <= lastDayOfWeek;
    };

    const isDateInLastWeek = (date: Date) => {
        const todayObj = new Date();
        const todayDate = todayObj.getDate();
        const todayDay = todayObj.getDay();

        // get first date of this week
        const firstDayOfThisWeek = new Date(todayObj.setDate(todayDate - todayDay));

        // get first day of last week
        const lastDayOfLastWeek = new Date(todayObj.getFullYear(), todayObj.getMonth(), todayObj.getDate() - 7);

        // if date is equal or within the first and last dates of the week
        return date < firstDayOfThisWeek && date >= lastDayOfLastWeek;
    };

    const groupNotificationsByTime = useCallback((notificationsData: Notification[], setNotificationObject: (value: SetStateAction<TimelineNotifications | undefined>) => void) => {
        const tempAllNotifications: TimelineNotifications = {
            today: [],
            thisWeek: [],
            lastWeek: [],
            earlier: [],
        };
        if (notificationsData.length > 0) {
            const today = new Date();
            notificationsData.forEach((notification) => {
                if (notification.updated_at) {
                    const currentDateString = notification.created_at.split('T')[0];
                    const todayDateString = formatDateToString(today.toDateString(), 'YYYY-MM-DD');
                    const currentDate = new Date(notification.created_at);

                    if (currentDateString === todayDateString) {
                        // Check if today.
                        tempAllNotifications.today.push(notification);
                    } else if (isDateInThisWeek(currentDate)) {
                        // Check if this week.
                        tempAllNotifications.thisWeek.push(notification);
                    } else if (isDateInLastWeek(currentDate)) {
                        // Check if last week.
                        tempAllNotifications.lastWeek.push(notification);
                    } else {
                        // Else it was Earlier.
                        tempAllNotifications.earlier.push(notification);
                    }
                }
            });
            setNotificationObject(tempAllNotifications);
        }
    }, []);

    useEffect(() => {
        // All
        groupNotificationsByTime(notifications, setAllNotifications);

        // Releases
        const tempReleasesNotifications = notifications.filter((n) =>
            n.type_identifier === NotificationTypeIdentifier.NewArtistRelease ||
            n.type_identifier === NotificationTypeIdentifier.NewLabelRelease);
        groupNotificationsByTime(tempReleasesNotifications, setReleasesNotifications);

        // Playlist
        const tempPlaylistNotifications = notifications.filter((n) =>
            n.type_identifier === NotificationTypeIdentifier.NewPlaylistUpdate ||
            n.type_identifier === NotificationTypeIdentifier.NewRecommendation);
        groupNotificationsByTime(tempPlaylistNotifications, setPlaylistNotifications);

        // System
        const tempSystemNotifications = notifications.filter((n) => n.type_identifier === NotificationTypeIdentifier.CrateDownloadReady);
        groupNotificationsByTime(tempSystemNotifications, setSystemNotifications);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifications]);

    const [selectedPage, setSelectedPage] = useState<number>(0);

    const pagesData = useCallback(() => [
        {
            text: 'All',
            enabled: true,
            onSelected: (index: number) => {
                setSelectedPage(index);
            }
        },
        {
            text: platform === Notification.PlatformEnum.Create ? 'Labels' : 'Releases',
            enabled: true,
            onSelected: (index: number) => {
                setSelectedPage(index);
            }
        },
        {
            text: platform === Notification.PlatformEnum.Create ? 'Featured' : 'Playlist',
            enabled: true,
            onSelected: (index: number) => {
                setSelectedPage(index);
            }
        },
        {
            text: 'System',
            enabled: true,
            onSelected: (index: number) => {
                setSelectedPage(index);
            }
        },
    ], [platform]);

    const renderNotifications = useCallback((notificationGroup?: TimelineNotifications) => {
        if (notificationGroup === undefined) {
            return <EmptyState
                title="Looks like you haven’t received any notifications yet."
                subtitle={isCreate ? 'Follow labels and curated packs to be notified as soon as new content is available.' : 'Follow and save artists, playlists and more to be notified as soon as new music is available.'}
                noPadding
                icon="notification-bell-icon"
                variant="dynamic"
                actionIcon="chevron-right-icon"
                actionLabel={isCreate ? 'View Top Labels' : 'View Trending Artists'}
                onPress={() => {
                    if (isCreate) {
                        router.push(getPlatformLinkUsingRouter('/labels'));
                    } else {
                        router.push(getPlatformLinkUsingRouter('/trending'));
                    }
                    onClose();
                }}
                hasBackground={false} />;
        }
        return (
            <ul className={styles['user-notifications__items']}>
                {notificationGroup.today.length > 0 ?
                    <NotificationSection title="Today" notifications={notificationGroup.today} onClose={onClose} onDeleteNotification={handleDeleteNotification} platform={platform} isPlaying={isPlaying} handlePlay={handlePlayMedia} togglePlayPause={togglePlayPause} isMobile={isMobile} />
                    : null}
                {notificationGroup.thisWeek.length > 0 ?
                    <NotificationSection title="This Week" notifications={notificationGroup.thisWeek} onClose={onClose} onDeleteNotification={handleDeleteNotification} platform={platform} isPlaying={isPlaying} handlePlay={handlePlayMedia} togglePlayPause={togglePlayPause} isMobile={isMobile} />
                    : null}
                {notificationGroup.lastWeek.length > 0 ?
                    <NotificationSection title="Last Week" notifications={notificationGroup.lastWeek} onClose={onClose} onDeleteNotification={handleDeleteNotification} platform={platform} isPlaying={isPlaying} handlePlay={handlePlayMedia} togglePlayPause={togglePlayPause} isMobile={isMobile} />
                    : null}
                {notificationGroup.earlier.length > 0 ?
                    <NotificationSection title="Earlier" notifications={notificationGroup.earlier} onClose={onClose} onDeleteNotification={handleDeleteNotification} platform={platform} isPlaying={isPlaying} handlePlay={handlePlayMedia} togglePlayPause={togglePlayPause} isMobile={isMobile} />
                    : null}
            </ul>
        );
    }, [handleDeleteNotification, handlePlayMedia, isCreate, isMobile, isPlaying, onClose, platform, router, togglePlayPause]);

    const currentPage = useMemo(() => {
        switch (selectedPage) {
            case 0:
                return renderNotifications(allNotifications);
            case 1:
                return renderNotifications(releasesNotifications);
            case 2:
                return renderNotifications(playlistNotifications);
            case 3:
                return renderNotifications(systemNotifications);
            default:
                return null;
        }
    }, [selectedPage, renderNotifications, allNotifications, releasesNotifications, playlistNotifications, systemNotifications]);

    const threeDotsContext = useContext(ThreeDotsSheetContext);
    const createThreeDotsContext = useContext(CreateThreeDotsSheetContext);

    if (isLoading || error) return null;
    return (
        <div className={classNames(styles[`user-notifications${isMobile ? '__container-mobile' : '__container'}`], { [styles[`user-notifications${isMobile ? '__container-mobile' : '__container'}--open`]]: isVisible })}>
            <div className={styles['user-notifications__header']}>
                <div className={styles['user-notifications__header--row']}>
                    {isMobile && (
                        <button
                            type="button"
                            aria-label="Close notifications"
                            className={styles['user-notifications__close']}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onClose();
                            }}>
                            <ChevronLeft />
                        </button>
                    )}
                    <h4>Notifications</h4>

                    {!isMobile ? (
                        <div className={styles['user-notifications__header--clear-buttons']}>

                            <button type="button" aria-label="clear all" className={styles['user-notifications__clear-button']} onClick={handleClearAll}>
                                {notifications.length > 0 ? 'Clear All' : null}
                            </button>
                            <button
                                type="button"
                                aria-label="close"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onClose();
                                }}
                                className={styles['user-notifications__cross-button']}>
                                <BPMIcons.CloseIcon />
                            </button>
                        </div>
                    ) : (
                        <div className={styles['user-notifications__header--clear-buttons']}>
                            <button type="button" aria-label="clear all" className={styles['user-notifications__clear-button']} onClick={handleClearAll}>
                                {notifications.length > 0 ? 'Clear All' : null}
                            </button>
                        </div>
                    )}
                </div>
                <PageSwitcher pages={pagesData()} selected={selectedPage} variant="dynamic" />
            </div>
            <ThreeDotsSheetContext.Consumer>
                {(value) => {
                    return (value.actionTypeId !== -1 && value.renderLocation === 'notification' ? <ThreeDotsSheet /> : null);
                }}
            </ThreeDotsSheetContext.Consumer>
            <CreateThreeDotsSheetContext.Consumer>
                {(value) => (value.actionTypeId !== -1 && value.renderLocation === 'notification' ? <CreateThreeDotsSheet /> : null)}
            </CreateThreeDotsSheetContext.Consumer>
            <div className={classNames(styles['user-notifications__items-container'], 'notifications-scroll-container', {
                [styles['user-notifications__items-container--modal-open']]: (threeDotsContext.actionTypeId !== -1 && threeDotsContext.renderLocation === 'notification') || (createThreeDotsContext.actionTypeId !== -1 && createThreeDotsContext.renderLocation === 'notification')
            })}>
                {currentPage}
            </div>
        </div>
    );
}
