import { CuratedSet, Label, Preset, Sound, SoundPackage } from '@bpm-web-app/create-api-sdk';
import { createAppRoutes, soundPackageToCreatePlayable } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { convertCuratedToCarouselProps, convertLabelToCarouselProps, convertSoundPackageToCarouselProps } from 'libs/utils/src/lib/transform';
import Link from 'next/link';
import { useCallback } from 'react';
import { ClickableGenres } from '../../../clickable-genres/clickable-genres';
import { CreateCardCarousel } from '../../../create-card-carousel/create-card-carousel';
import { useCreatePlayer } from '../../../../../../utils/src/lib/create-player.context';
import SecondaryPageTitle from '../../../shared/secondary-page-title/secondary-page-title';
import TrackListCreatePresets from '../../../shared/track-list/create-presets/track-list-create-presets';
import TrackListCreate from '../../../shared/track-list/create/track-list-create';
import styles from './favorites-desktop.module.css';
import { FavoriteEmptySpaceProps } from './favorites-desktop';
import { EmptyState } from '../../../shared';
import SeeMore from '../../../shared/see-more-button/see-more-btn';

interface FavoritesDesktopCreateProps {
    soundPacks: SoundPackage[];
    soundPacksCount: number;
    sounds: Sound[];
    soundsCount: number;
    presets: Preset[];
    presetCount: number;
    curatedSets: CuratedSet[];
    curatedSetsCount: number;
    labels: Label[];
    labelsCount: number;
}

export const FAVORITE_EMPTY_STATES: FavoriteEmptySpaceProps[] = [
    {
        text: 'Packs',
        emptyStateProps: {
            title: "Looks like you haven't saved any packs yet.",
            subtitle: 'Save packs to reference later, organize your favorites, and improve recommendations.',
            actionLabel: 'Explore Trending Packs',
            href: 'sound-packages/featured'
        },
    },
    {
        text: 'Sounds',
        emptyStateProps: {
            title: "Looks like you haven't saved any sounds yet.",
            subtitle: 'Save sounds to reference later, organize your favorites, and improve recommendations.',
            actionLabel: 'Explore Trending Sounds',
            href: 'trending'
        },
    },
    {
        text: 'Synth Presets',
        emptyStateProps: {
            title: "Looks like you haven't saved any synth presets yet.",
            subtitle: 'Save synth presets to reference later, organize your favorites, and improve recommendations.',
            actionLabel: 'Explore Trending Presets',
            href: 'presets'
        },
    },
    {
        text: 'Curated Packs',
        emptyStateProps: {
            title: "Looks like you haven't saved any curated packs yet.",
            subtitle: "Save curated packs to get notified when they're updated with new music.",
            actionLabel: 'Explore Curated Packs',
            href: 'curated'
        },
    },
    {
        text: 'Labels',
        emptyStateProps: {
            title: "Looks like you haven't saved any labels sets yet.",
            subtitle: 'Save labels and get notified when they release new soundpacks.',
            actionLabel: 'Explore Labels',
            href: 'labels'
        },
    }
];

export function FavoritesDesktopCreate({ soundPacks, sounds, presets, curatedSets, labels, soundPacksCount, presetCount, soundsCount, curatedSetsCount, labelsCount }: FavoritesDesktopCreateProps) {
    const { playDemo } = useCreatePlayer();

    const getNoResultsSection = useCallback(
        ({ text, emptyStateProps }: FavoriteEmptySpaceProps) => (
            <div className={classNames(styles['favorites-desktop__no-results-section'])}>
                <SecondaryPageTitle title={text} counter="0" noPadding />
                <EmptyState {...emptyStateProps} actionIcon="chevron-right-icon" noPadding icon="heart-outline-icon" />
            </div>
        ), []
    );

    return (
        <>
            {soundPacksCount > 0 ? (
                <CreateCardCarousel
                    contentType="pack"
                    items={soundPacks}
                    cardSize="small"
                    carouselMorePath={createAppRoutes.favoritesPacks}
                    isLoading={false}
                    carouselTitle="Packs"
                    carouselTitleCounter={`${soundPacksCount}`}
                    noPadding
                />) : (
                getNoResultsSection(FAVORITE_EMPTY_STATES[0])
            )}
            {soundsCount > 0 ? (
                <div className={styles['favorites-desktop__track-list-container']}>
                    <SecondaryPageTitle title="Sounds" counter={`${soundsCount}`} noPadding />
                    <TrackListCreate list={sounds} />
                    {soundsCount > sounds?.length && (
                        <Link href={createAppRoutes.favoritesSounds}>
                            <SeeMore expand={false} variant="text" />
                        </Link>)}
                </div>) : (
                getNoResultsSection(FAVORITE_EMPTY_STATES[1])
            )}
            {presetCount > 0 ? (
                <div className={classNames(styles['favorites-desktop__track-list-container'], styles['favorites-desktop__track-list-container--presets'])}>
                    <SecondaryPageTitle title="Synth Presets" counter={`${presetCount}`} noPadding />
                    <TrackListCreatePresets list={presets} />
                    {presetCount > presets?.length && (
                        <Link href={createAppRoutes.favoritesPresets}>
                            <SeeMore expand={false} variant="text" />
                        </Link>)}
                </div>) : (
                getNoResultsSection(FAVORITE_EMPTY_STATES[2])
            )}
            {curatedSetsCount > 0 ? (
                <CreateCardCarousel
                    contentType="curated-set"
                    items={curatedSets}
                    cardSize="small"
                    carouselMorePath={createAppRoutes.favoriteCurated}
                    isLoading={false}
                    carouselTitle="Curated Packs"
                    carouselTitleCounter={`${curatedSetsCount}`}
                    noPadding
                />) : (
                getNoResultsSection(FAVORITE_EMPTY_STATES[3])
            )}
            {labelsCount > 0 ? (
                <CreateCardCarousel
                    contentType="label"
                    items={labels}
                    cardSize="small"
                    carouselMorePath={createAppRoutes.favoriteLabels}
                    isLoading={false}
                    carouselTitle="Labels"
                    carouselTitleCounter={`${labelsCount}`}
                    noPadding
                />) : (
                getNoResultsSection(FAVORITE_EMPTY_STATES[4])
            )}

        </>
    );
}
