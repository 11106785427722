import { useCreateLike, useDownloadCuratedSet, useDownloadSoundPackage, useGetCredits } from '@bpm-web-app/swr-hooks';
import { Analytics, appendQueryParams, CreateCardCarouselItem, DEFAULT_BROWSE_CARD_LIMIT, fileDownload, showToast, useApiErrorHandler } from '@bpm-web-app/utils';
import { Fragment, useCallback, useMemo } from 'react';
import dayjs from 'dayjs';

import { UrlObject } from 'url';
import { useRouter } from 'next/router';
import { CuratedSet, Label, SoundPackage } from '@bpm-web-app/create-api-sdk';
import CardCarousel from '../shared/card-carousel/card-carousel';
import { GhostComponent } from '../shared';
import CardCreate, { CreateCardSize } from '../shared/card/card-create';
import NoResultsBlock from '../shared/ui/no-results-block/no-results-block';
import { CreateActionType } from '../shared/three-dots-sheet/create-three-dots-sheet.context';
import { CreateCardContentTypeWithoutActions } from '../shared/card/card-image-overlay/card-image-overlay';
import { PackCardCreate } from '../shared/card/pack-card-create/pack-card-create';
import { CuratedSetCardCreate } from '../shared/card/curated-set-card-create/curated-set-card-create';
import { LabelCardCreate } from '../shared/card/label-card-create/label-card-create';

export interface CreateCardCarouselProps {
    cardSize: CreateCardSize;
    carouselTitle: string;
    carouselTitleCounter?: string;
    carouselMorePath?: string | UrlObject;
    items: SoundPackage[] | CuratedSet[] | CreateCardCarouselItem[] | Label[];
    isLoading?: boolean;
    limit?: number;
    onCardPlay?: (id: string) => void;
    noResultsText?: string;
    itemBasePath?: string;
    isContest?: boolean;
    isCardPlayable?: boolean;
    contentType: CreateActionType | CreateCardContentTypeWithoutActions;
    isInContainer?: boolean;
    noPadding?: boolean;
    linesWidthArrayGhost?: number[];
    lineHeightGhost?: number;
}

export function CreateCardCarousel({
    cardSize,
    carouselTitle,
    carouselTitleCounter,
    carouselMorePath,
    items,
    limit = DEFAULT_BROWSE_CARD_LIMIT,
    isLoading,
    onCardPlay,
    noResultsText,
    itemBasePath,
    contentType,
    isInContainer,
    noPadding = false,
    linesWidthArrayGhost,
    lineHeightGhost
}: CreateCardCarouselProps) {
    const downloadPack = useDownloadSoundPackage();
    const downloadCuratedSet = useDownloadCuratedSet();

    const slicedData = useMemo(() => (items ? items.slice(0, limit) : []), [items, limit]);

    const likeType = useMemo(() => {
        switch (contentType) {
            case 'pack':
                return 'soundPackage';
            case 'curated-set':
                return 'curated';
            case 'sound':
                return 'sound';
            case 'label':
                return 'label';
            case 'contest':
            case 'news':
                return undefined;
            default:
                /* TODO: revisit - the default should probably be undefined too */
                return 'label';
        }
    }, [contentType]);

    const { isLiked, likeDislike } = useCreateLike(likeType);
    const router = useRouter();
    const { mutate: refreshCredits } = useGetCredits();
    const errorHandler = useApiErrorHandler();

    const handleDownloadUrl = useCallback(
        (url: string) => {
            fileDownload(url);
            refreshCredits();
        },
        [refreshCredits]
    );

    const handleDownloadPack = useCallback(async (contentId: string) => {
        try {
            const response = await showToast({
                promise: contentType === 'curated-set' ? downloadCuratedSet(contentId, router.asPath) : downloadPack(contentId, router.asPath),
                message: 'Download in progress.',
                successText: 'Download successful.',
                preventErrorToast: true
            });
            if (response && response.data && response.data.url) handleDownloadUrl(response.data.url);
            if (contentId) {
                if (contentType === 'curated-set') Analytics.trackClick('download_curated', contentId, { location: `${contentType}-card-carousel` });
                if (contentType === 'pack') Analytics.trackClick('download_pack', contentId, { location: `${contentType}-card-carousel` });
            }
        } catch (err) {
            errorHandler({ error: err });
        }
    }, [contentType, downloadCuratedSet, downloadPack, errorHandler, handleDownloadUrl, router.asPath]);

    const downloadFunction = useCallback((id: string) => {
        switch (contentType) {
            case 'curated-set':
            case 'pack':
                return handleDownloadPack(id);
            default:
                return () => null;
        }
    }, [contentType, handleDownloadPack]);

    const lineHeight = () => {
        if (lineHeightGhost) return lineHeightGhost;
        switch (cardSize) {
            case 'large-square':
                return 20;
            case 'small':
                return 19;
            default:
                return undefined;
        }
    };

    const linesWidthArray = () => {
        if (linesWidthArrayGhost) return linesWidthArrayGhost;
        switch (cardSize) {
            case 'small':
                return [80, 60, 90];
            default:
                return undefined;
        }
    };

    if (isLoading) {
        return <GhostComponent type="cards" cardSize={cardSize} title={carouselTitle} elementsCount={limit} linesWidthArray={linesWidthArray()} lineHeight={lineHeight()} isCreate />;
    }

    if (!isLoading && !slicedData.length && noResultsText) {
        return <NoResultsBlock verticalMargins={16}>{noResultsText}</NoResultsBlock>;
    }

    if (!isLoading && !slicedData.length) {
        return (
            <NoResultsBlock hasPadding>{`No ${carouselTitle} Available`}</NoResultsBlock>
        );
    }

    function contestDeadline(date: string) {
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1;
        let dd = today.getDate();
        if (dd < 10) dd = 0 + dd;
        if (mm < 10) mm = 0 + mm;
        const formattedToday = `${yyyy}-${mm}-${dd}`;

        const hasExpired = dayjs(date).isBefore(formattedToday);

        if (hasExpired === true) {
            return `Expired ${dayjs(date).tz('America/Los_Angeles').format('MMMM DD, YYYY')}`;
        }

        return `Deadline ${dayjs(date).tz('America/Los_Angeles').format('MMMM DD, YYYY')}`;
    }

    const renderCard = (item: CuratedSet | SoundPackage | CreateCardCarouselItem | Label) => {
        const convertedItem = item as CreateCardCarouselItem;
        switch (contentType) {
            case 'pack':
                return (
                    <Fragment key={`pack-card-create-${item.id}`}>
                        <PackCardCreate item={item as unknown as SoundPackage} items={items as unknown as SoundPackage[]} />
                    </Fragment>
                );
            case 'curated-set':
                return (
                    <Fragment key={`curated-set-card-create-${item.id}`}>
                        <CuratedSetCardCreate item={item as unknown as CuratedSet} items={items as unknown as CuratedSet[]} />
                    </Fragment>
                );
            case 'label':
                return (
                    <Fragment key={`label-card-create-${item.id}`}>
                        <LabelCardCreate item={item as unknown as Label} items={items as unknown as Label[]} />
                    </Fragment>
                );
            default:
                return (
                    <CardCreate
                        contentType={contentType}
                        cardSize={cardSize}
                        key={convertedItem.id}
                        id={`${convertedItem.id}`}
                        title={convertedItem.title}
                        approved={convertedItem.approved}
                        imageUrl={appendQueryParams(convertedItem.coverUrl, { key: 'dw', value: cardSize === 'large' || cardSize === 'large-square' ? 264 : 160 })}
                        imageUrl2x={appendQueryParams(convertedItem.coverUrl, { key: 'dw', value: cardSize === 'large' || cardSize === 'large-square' ? 528 : 320 })}
                        label={convertedItem.label ?? undefined}
                        onCardPlay={convertedItem.isPlayable ? onCardPlay : undefined}
                        description={contentType === 'contest' ? contestDeadline(convertedItem.submission_deadline!) : convertedItem.description}
                        onFavorite={likeDislike}
                        isFavorite={isLiked(convertedItem.id)}
                        shareURL={itemBasePath ? `${itemBasePath}/${encodeURIComponent(convertedItem.slug)}` : undefined}
                        link={itemBasePath ? `${itemBasePath}/${encodeURIComponent(convertedItem.slug)}` : undefined}
                        hasExtraDemo={convertedItem.hasExtraDemo}
                        badge={convertedItem.badge}
                        items={items}
                        onDownload={downloadFunction}
                    />
                );
        }
    };

    return (
        <CardCarousel title={carouselTitle} titleCounter={carouselTitleCounter} seeMorePath={carouselMorePath} isInContainer={isInContainer} noPadding={noPadding}>
            {slicedData?.map((item) => renderCard(item))}
        </CardCarousel>
    );
}
