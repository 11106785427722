import classNames from 'classnames';
import { SVGAttributes, MouseEvent, useEffect, ReactElement } from 'react';
import { rebuildReactTooltip } from '@bpm-web-app/utils';
import BPMIcons from '../bpm-icons/bpm-icons';
import styles from './custom-icon.module.css';
import { CustomIconType } from './custom-icon-types';

type CustomIconContainer = 'square' | 'round'; // Container style
type CustomIconSize = 'regular' | 'small' | 'large' | number; // Icon size 24px, 12px, 48px
type CustomIconContainerSize = 'regular' | 'small' | 'large' | number; // Container size 40px, 16px, 54px
export type CustomIconColor = 'gray' | 'dynamic' | 'red' | 'white' | 'black' | 'boulder' | 'delete' | 'current';
type CustomIconBackgroundColor = 'transparent' | 'gray' | 'dynamic' | 'red' | 'white';
type CustomIconSVGStyle = 'path' | 'g-path' | 'stroke-path' | 'path-stroke-fill';
type CustomIconFlipOrientation = 'vertical' | 'horizontal';

interface CustomIconProps {
    type?: CustomIconType;
    onClick?: (e: MouseEvent<HTMLElement>) => void;
    container?: CustomIconContainer;
    size?: CustomIconSize;
    iconRotation?: number;
    containerSize?: CustomIconContainerSize;
    color?: CustomIconColor;
    backgroundColor?: CustomIconBackgroundColor;
    flip?: CustomIconFlipOrientation;
    hasIconHover?: boolean;
    hasBackgroundHover?: boolean;
    hasBackgroundBorderHover?: boolean;
    isIconHoverActive?: boolean;
    isBackgroundHoverActive?: boolean;
    tooltip?: string;
    tooltipBottomOffset?: number;
    hideToolTipOnFocus?: boolean;
    className?: string;
    hasActiveState?: boolean;
    children?: ReactElement;
    hasToolTipRevealDelay?: boolean;
}

export function CustomIcon(iconProps: CustomIconProps) {
    const {
        type,
        onClick,
        container = 'square',
        size = 'regular',
        containerSize,
        color = 'gray',
        backgroundColor,
        flip,
        hasIconHover = false,
        hasBackgroundHover = false,
        hasBackgroundBorderHover = false,
        isIconHoverActive = false,
        isBackgroundHoverActive = false,
        tooltip,
        hideToolTipOnFocus,
        tooltipBottomOffset,
        className,
        hasActiveState,
        children,
        hasToolTipRevealDelay = false,
        iconRotation,
    } = iconProps;

    const iconSize = () => {
        switch (size) {
            case 'regular':
                return 24;
            case 'small':
                return 12;
            case 'large':
                return 48;
            default:
                return size;
        }
    };

    const customIconSVGStyle = (): CustomIconSVGStyle => {
        switch (type) {
            case 'checkmark':
            case 'heart-outline-icon':
            case 'chevron-right-icon':
                return 'stroke-path';
            case 'email-icon':
            case 'three-dots':
            case 'add-person':
            case 'shared-with-me':
            case 'share-arrow-icon':
            case 'plus-icon':
            case 'play-button-round-icon':
            case 'pause-button-round-icon':
            case 'follow-active-icon':
            case 'add-to-playlist-icon-lg':
            case 'add-to-playlist-icon':
            case 'add-circle-icon':
            case 'download-icon':
            case 'add-to-crate-icon':
            case 'find-similar-icon':
            case 'delete-filled-icon':
            case 'volume-icon':
            case 'volume-mute-icon':
                return 'path';
            case 'heart-filled-icon':
                return 'path-stroke-fill';
            default:
                return 'g-path';
        }
    };

    const svgIconProps: SVGAttributes<SVGSVGElement> = {
        height: iconSize(),
        width: iconSize(),
        className: classNames(styles['custom-icon__icon'], styles[`custom-icon__icon__color__${customIconSVGStyle()}--${color}`], { [styles[`custom-icon__icon__flip--${flip}`]]: flip }),
    };

    const customIcon = () => {
        switch (type) {
            case 'notification-bell-icon':
                return <BPMIcons.NotificationBellIcon {...svgIconProps} />;
            case 'notification-bell-active-icon':
                return <BPMIcons.NotificationBellActiveIcon {...svgIconProps} />;
            case 'shared-with-me':
                return <BPMIcons.SharedWithMe {...svgIconProps} />;
            case 'add-person':
                return <BPMIcons.FollowIcon {...svgIconProps} />;
            case 'pencil-outline':
                return <BPMIcons.EditPencilOutlineIcon {...svgIconProps} />;
            case 'profile-icon':
                return <BPMIcons.ProfileIcon {...svgIconProps} />;
            case 'email-icon':
                return <BPMIcons.EmailIcon {...svgIconProps} />;
            case 'three-dots':
                return <BPMIcons.ThreeDotsIcon {...svgIconProps} />;
            case 'cross-circle':
                return <BPMIcons.CircleCrossIcon {...svgIconProps} />;
            case 'circle-minus':
                return <BPMIcons.CircleMinusIcon {...svgIconProps} />;
            case 'checkmark':
                return <BPMIcons.CheckmarkDefaultIcon {...svgIconProps} />;
            case 'shared-folder':
                return <BPMIcons.SharedFolderIcon {...svgIconProps} />;
            case 'folder-icon':
                return <BPMIcons.FolderIcon {...svgIconProps} />;
            case 'arrow-right':
                return <BPMIcons.ArrowRight {...svgIconProps} />;
            case 'plus-icon':
                return <BPMIcons.PlusIcon {...svgIconProps} />;
            case 'chevron-right-icon':
                return <BPMIcons.ChevronRightIcon {...svgIconProps} />;
            case 'heart-outline-icon':
                return <BPMIcons.FavoriteHeartIcon {...svgIconProps} />;
            case 'heart-filled-icon':
                return <BPMIcons.FavoriteHeartFilledIcon {...svgIconProps} />;
            case 'search-outline-icon':
                return <BPMIcons.SearchIcon {...svgIconProps} />;
            case 'play-button-round-icon':
                return <BPMIcons.RoundPlayIcon {...svgIconProps} />;
            case 'pause-button-round-icon':
                return <BPMIcons.RoundPauseIcon {...svgIconProps} />;
            case 'add-circle-icon':
                return <BPMIcons.AddCircleIcon {...svgIconProps} />;
            case 'follow-active-icon':
                return <BPMIcons.FollowActiveIcon {...svgIconProps} />;
            case 'share-url-icon':
                return <BPMIcons.ShareIcon {...svgIconProps} />;
            case 'share-arrow-icon':
                return <BPMIcons.ShareArrowIcon {...svgIconProps} />;
            case 'edit-pencil-icon':
                return <BPMIcons.EditPencilIcon {...svgIconProps} />;
            case 'delete-filled-icon':
                return <BPMIcons.DeleteFilledIcon {...svgIconProps} />;
            case 'new-window-icon':
                return <BPMIcons.NewWindowIcon {...svgIconProps} />;
            case 'sort-icon':
                return <BPMIcons.SortIcon {...svgIconProps} />;
            case 'add-to-playlist-icon':
                return <BPMIcons.AddToPlaylistIcon {...svgIconProps} />;
            case 'add-to-playlist-icon-lg':
                return <BPMIcons.AddToPlaylistLargeIcon {...svgIconProps} />;
            case 'download-icon':
                return <BPMIcons.DownloadIcon {...svgIconProps} />;
            case 'add-to-crate-icon':
                return <BPMIcons.AddToCrateIcon {...svgIconProps} />;
            case 'remove-from-crate-icon':
                return <BPMIcons.RemoveFromCrateIcon className={styles['custom-icon__icon']} />;
            case 'find-similar-icon':
                return <BPMIcons.FindSimilarIcon {...svgIconProps} />;
            case 'featured-in-icon':
                return <BPMIcons.FeaturedInIcon {...svgIconProps} />;
            case 'switch-platforms-icon':
                return <BPMIcons.SwitchPlatformIcon {...svgIconProps} />;
            case 'volume-icon':
                return <BPMIcons.VolumeIcon {...svgIconProps} />;
            case 'volume-mute-icon':
                return <BPMIcons.VolumeMuteIcon {...svgIconProps} />;
            case 'filters-icon':
                return <BPMIcons.FiltersIcon {...svgIconProps} />;
            case 'close-icon':
                return <BPMIcons.CloseIcon {...svgIconProps} />;
            case 'lock-premium-icon':
                return <BPMIcons.LockPremiumIcon {...svgIconProps} />;
            case 'sound-wave-icon':
                return <BPMIcons.SoundWaveIcon {...svgIconProps} />;
            default:
                return null;
        }
    };

    useEffect(() => {
        if (tooltip) rebuildReactTooltip();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const containerSizeStyle = Number(containerSize) ? { height: Number(containerSize), width: Number(containerSize) } : {};

    return (
        <div
            tabIndex={0}
            role="button"
            onKeyDown={() => {}}
            onClick={onClick}
            data-tip={tooltip}
            data-offset={tooltipBottomOffset ? `{'bottom': ${tooltipBottomOffset}}` : undefined}
            data-tip-show
            data-delay-show={hasToolTipRevealDelay ? '1500' : '400'}
            className={classNames(
                styles['custom-icon'],
                className,
                { [styles['custom-icon--current']]: color === 'current' },
                { [styles[`custom-icon__container--${container}`]]: container },
                { [styles[`custom-icon__container__size--${containerSize}`]]: containerSize && !Number(containerSize) },
                { [styles[`custom-icon__container__background-color--${backgroundColor}`]]: backgroundColor },
                { [styles[`custom-icon__container__background-color--${backgroundColor || 'transparent'}--active`]]: hasActiveState && !tooltip },
                { [styles['custom-icon__icon-hover']]: hasIconHover },
                { [styles['custom-icon__background-hover']]: hasBackgroundHover },
                { [styles['custom-icon__background-hover__border']]: hasBackgroundBorderHover },
                { [styles['custom-icon__background-hover--active']]: hasActiveState && tooltip && hasBackgroundHover },
                { [styles['custom-icon__background-hover__border--active']]: hasActiveState && tooltip && hasBackgroundBorderHover },
                { [styles['custom-icon__background-hover-active']]: isBackgroundHoverActive },
                { [styles['custom-icon__background-hover-active--border']]: isBackgroundHoverActive && hasBackgroundBorderHover },
                { [styles['custom-icon__icon-hover-active']]: isIconHoverActive },
                { [styles['custom-icon__hide-on-focus-tooltip']]: hideToolTipOnFocus && tooltip }
            )}
            style={{ ...containerSizeStyle, rotate: iconRotation ? `${iconRotation}deg` : 'unset' }}
        >
            {customIcon()}
            {children}
        </div>
    );
}
