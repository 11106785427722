import classNames from 'classnames';
import { useViewport } from '@bpm-web-app/utils';
import styles from './ghost-component.module.css';
import { CardProps } from '../card/card';
import { CreateCardProps } from '../card/card-create';
import { CardCarouselGhostLoading } from '../card-carousel/card-carousel-ghost-loading';
import { TrendingGhost } from '../../trending/trending-ghost';
import GhostElement, { GhostElementProps } from '../ghost-element/ghost-element';
import { FollowButton } from '../follow-button/follow-button';
import { PlayButton } from '../play-button/play-button';
import { TrackListGhostLoading } from '../track-list/ghost-loading/track-list-ghost-loading';
import SecondaryPageTitle from '../secondary-page-title/secondary-page-title';
import { BreakpointView } from '../ui';
import { SquaredButton } from '../squared-button/squared-button';
import { CustomIcon } from '../custom-icon/custom-icon';
import { DownloadButton } from '../download-button/download-button';
import { TrackListPreset } from '../track-list/track-list';
import ArtistPortalList from '../../artist-portal-list/artist-portal-list';
import { ArtistPortalListPreset } from '../../artist-portal-list/artist-portal-list-helpers';
import TrackListCreatePresets from '../track-list/create-presets/track-list-create-presets';
import { TagsViewGhost } from '../../tags-view/tags-view-ghost';
import TrackListCreate from '../track-list/create/track-list-create';
import { ActionType } from '../three-dots-sheet/three-dots-sheet.context';
import { getCardLineHeight, getCardLinesWidthArray, getCardSize } from '../card/card-ghost-loading';
import { TrackListSupreme } from '../track-list/supreme/track-list-supreme';
import { TrackListPresetSupreme } from '../track-list/supreme/track-list-supreme-helpers';

interface GhostComponentProps {
    key?: string;
    type?: 'custom' | 'tags' | 'primary-title' | 'secondary-title' | 'banner' | 'cards' | 'artist-cards' | 'trending' | 'for-you-detail' | 'genre' | 'artist-detail' | 'playlist-detail' | 'my-drive-detail' | 'curated-set-detail' | 'album-detail' | 'pack-detail' | 'curated-pack-detail' | 'label-detail' | 'track-list' | 'track-list-item';
    elementsCount?: number;
    title?: string;
    cardSize?: CardProps['cardSize'] | CreateCardProps['cardSize'];
    noPadding?: boolean
    linesWidthArray?: GhostElementProps['linesWidthArray'];
    lineHeight?: GhostElementProps['lineHeight'];
    itemClass?: string;
    imageClass?: string;
    grid?: boolean;
    preset?: TrackListPreset | ArtistPortalListPreset | TrackListPresetSupreme;
    isArtistPortal?: boolean;
    isCreate?: boolean;
    hasTrackListTabs?: boolean;
    largeTags?: boolean;
    isCreatePreset?: boolean;
    height?: number | string;
    width?: number | string;
    contentType?: ActionType;
    hasShimmer?: boolean;
}

export function GhostComponent({ type, elementsCount, title, cardSize = 'small', linesWidthArray, lineHeight, itemClass, imageClass, noPadding, grid = false, preset = TrackListPreset.Download, isArtistPortal, isCreate, hasTrackListTabs, largeTags, isCreatePreset, height, width, contentType, hasShimmer = true }: GhostComponentProps) {
    const { isMobile } = useViewport();

    const renderElement = (elementType: GhostComponentProps['type']) => {
        switch (elementType) {
            case 'custom':
                return (<div className={noPadding ? '' : classNames('spacing__window--left')}><div className={classNames(styles[`ghost-component__${elementType}`], hasShimmer ? 'shimmer' : '')} style={{ height, width }} /></div>);
            case 'tags':
                return (<div className={noPadding ? '' : classNames('spacing__window--left')}><TagsViewGhost large={largeTags} /></div>);
            case 'primary-title':
                return (<div className={noPadding ? '' : classNames('spacing__window--left')}><div className={classNames(styles[`ghost-component__${elementType}`], hasShimmer ? 'shimmer' : '')} /></div>);

            case 'secondary-title':
                return (<div className={noPadding ? '' : classNames('spacing__window--left')}><div className={classNames(styles[`ghost-component__${elementType}`], hasShimmer ? 'shimmer' : '')} /></div>);
            case 'banner':
                return (
                    <>
                        <div className={classNames(styles['ghost-component'])}>
                            <div className={classNames(styles[`ghost-component__${elementType}`], hasShimmer ? 'shimmer' : '')} />
                        </div>
                        <div className="spacing--top" />
                    </>
                );
            case 'cards':
                return (
                    <CardCarouselGhostLoading
                        title={title}
                        elementsCount={elementsCount || 0}
                        cardSize={getCardSize(contentType, cardSize)}
                        itemClass={itemClass}
                        imageClass={imageClass}
                        lineHeight={getCardLineHeight(contentType, lineHeight)}
                        linesWidthArray={getCardLinesWidthArray(contentType, linesWidthArray)}
                        grid={grid}
                        noPadding={noPadding}
                    />
                );
            case 'artist-cards':
                return (
                    <CardCarouselGhostLoading
                        title={title}
                        elementsCount={elementsCount || 0}
                        cardSize={cardSize}
                        itemClass={itemClass}
                        imageClass={imageClass}
                        lineHeight={lineHeight}
                        linesWidthArray={linesWidthArray}
                        grid={grid}
                        noPadding={noPadding}
                        isArtist
                    />
                );
            case 'trending':
                return <TrendingGhost noPadding={noPadding} />;
            case 'genre':
                return (<div className={classNames(styles['ghost-component__list-ghost-item-wrapper'])}><GhostElement itemClass={classNames(styles['ghost-component__list-ghost-item'], hasShimmer ? 'shimmer' : '')} /></div>);
            case 'artist-detail':
                return (
                    <>
                        <div className={styles[`ghost-component__${elementType}`]}>
                            <div className={classNames(styles[`ghost-component__${elementType}--image-container`])}>
                                <div className={classNames(styles[`ghost-component__${elementType}--image`], hasShimmer ? 'shimmer' : '')} />
                            </div>
                            <div className={classNames(styles[`ghost-component__${elementType}--text-container`])}>
                                <div className={classNames(styles[`ghost-component__${elementType}--title`], hasShimmer ? 'shimmer' : '')} />

                                <BreakpointView
                                    desktopChildren={
                                        <div className={classNames(styles[`ghost-component__${elementType}--description`])}>
                                            <PlayButton type="play" isPlaying={false} onPress={() => null} removeHover fullWidth={isMobile} />
                                            <div className="spacing--left-half" />
                                            <FollowButton isFollowed={false} handleFollow={() => null} removeHover type="artist" fullWidth={isMobile} />
                                        </div>
                                    }
                                    mobileChildren={null} />
                            </div>
                        </div>
                        <BreakpointView
                            desktopChildren={null}
                            mobileChildren={(
                                <div className={classNames(styles[`ghost-component__${elementType}--description`])}>
                                    <PlayButton type="play" isPlaying={false} onPress={() => null} removeHover fullWidth={isMobile} />
                                    <div className="spacing--left-half" />
                                    <FollowButton isFollowed={false} handleFollow={() => null} removeHover type="artist" fullWidth={isMobile} />
                                </div>
                            )} />

                    </>
                );
            case 'playlist-detail':
                return (
                    <>
                        <div className={styles[`ghost-component__${elementType}`]}>
                            <div className={classNames(styles[`ghost-component__${elementType}--image-container`])}>
                                <div className={classNames(styles[`ghost-component__${elementType}--image`], hasShimmer ? 'shimmer' : '')} />
                            </div>
                            <div className={classNames(styles[`ghost-component__${elementType}--text-container`])}>
                                <BreakpointView
                                    desktopChildren={(
                                        <div>
                                            <div className={classNames(styles[`ghost-component__${elementType}--title`], hasShimmer ? 'shimmer' : '')} />
                                            <div className={classNames(styles[`ghost-component__${elementType}--info`], hasShimmer ? 'shimmer' : '')} />
                                            <div className={classNames(styles[`ghost-component__${elementType}--artists`], hasShimmer ? 'shimmer' : '')} />
                                        </div>
                                    )}
                                    mobileChildren={null} />
                                <BreakpointView
                                    desktopChildren={(
                                        <div className={classNames(styles[`ghost-component__${elementType}--description`])}>
                                            <PlayButton type="play" isPlaying={false} onPress={() => null} removeHover fullWidth={isMobile} />
                                        </div>
                                    )}
                                    mobileChildren={null} />
                            </div>
                        </div>
                        <BreakpointView
                            desktopChildren={null}
                            mobileChildren={(
                                <div className={classNames(styles[`ghost-component__${elementType}--text-container`], 'spacing--left')}>

                                    <div className={classNames(styles[`ghost-component__${elementType}--title`], hasShimmer ? 'shimmer' : '')} />
                                    <div className={classNames(styles[`ghost-component__${elementType}--info`], hasShimmer ? 'shimmer' : '')} />
                                    <div className={classNames(styles[`ghost-component__${elementType}--artists`], hasShimmer ? 'shimmer' : '')} />
                                </div>
                            )} />

                        <BreakpointView
                            desktopChildren={null}
                            mobileChildren={(
                                <div className={classNames(styles[`ghost-component__${elementType}--description`])}>
                                    <PlayButton type="play" isPlaying={false} onPress={() => null} removeHover fullWidth={isMobile} />
                                </div>
                            )} />
                    </>
                );
            case 'my-drive-detail':
                return (
                    <>
                        <div className={styles[`ghost-component__${elementType}`]}>
                            <div className={classNames(styles[`ghost-component__${elementType}--image-container`])}>
                                <div className={classNames(styles[`ghost-component__${elementType}--image`], hasShimmer ? 'shimmer' : '')} />
                            </div>
                            <div className={classNames(styles[`ghost-component__${elementType}--text-container`])}>
                                <BreakpointView
                                    desktopChildren={(
                                        <div>
                                            <div className={classNames(styles[`ghost-component__${elementType}--title`], hasShimmer ? 'shimmer' : '')} />
                                            <div className={classNames(styles[`ghost-component__${elementType}--info`], hasShimmer ? 'shimmer' : '')} />
                                            <div className={classNames(styles[`ghost-component__${elementType}--artists`], hasShimmer ? 'shimmer' : '')} />
                                        </div>
                                    )}
                                    mobileChildren={null} />
                                <BreakpointView
                                    desktopChildren={(
                                        <div className={classNames(styles[`ghost-component__${elementType}--description`])}>
                                            <DownloadButton onPress={() => null} type="dynamic" fullWidth={isMobile} />
                                        </div>
                                    )}
                                    mobileChildren={null} />
                            </div>
                        </div>
                        <BreakpointView
                            desktopChildren={null}
                            mobileChildren={(
                                <div className={classNames(styles[`ghost-component__${elementType}--text-container`], 'spacing--left')}>

                                    <div className={classNames(styles[`ghost-component__${elementType}--title`], hasShimmer ? 'shimmer' : '')} />
                                    <div className={classNames(styles[`ghost-component__${elementType}--info`], hasShimmer ? 'shimmer' : '')} />
                                    <div className={classNames(styles[`ghost-component__${elementType}--artists`], hasShimmer ? 'shimmer' : '')} />
                                </div>
                            )} />
                    </>
                );
            case 'curated-set-detail':
                return (
                    <>
                        <div className={styles[`ghost-component__${elementType}`]}>
                            <div className={classNames(styles[`ghost-component__${elementType}--image-container`])}>
                                <div className={classNames(styles[`ghost-component__${elementType}--image`], hasShimmer ? 'shimmer' : '')} />
                            </div>
                            <div className={classNames(styles[`ghost-component__${elementType}--text-container`])}>
                                <BreakpointView
                                    desktopChildren={(
                                        <div>
                                            <div className={classNames(styles[`ghost-component__${elementType}--title`], hasShimmer ? 'shimmer' : '')} />
                                            <div className={classNames(styles[`ghost-component__${elementType}--info`], hasShimmer ? 'shimmer' : '')} />
                                            <div className={classNames(styles[`ghost-component__${elementType}--artists`], hasShimmer ? 'shimmer' : '')} />
                                        </div>
                                    )}
                                    mobileChildren={null} />
                                <BreakpointView
                                    desktopChildren={(
                                        <div className={classNames(styles[`ghost-component__${elementType}--description`])}>
                                            <PlayButton type="play" isPlaying={false} onPress={() => null} removeHover fullWidth={isMobile} />
                                            <div className="spacing--left-half" />
                                            <SquaredButton onPress={() => null} removeHover fullWidth={isMobile} type="outline" leftIcon={<CustomIcon type="heart-outline-icon" />} label="Save" />
                                        </div>
                                    )}
                                    mobileChildren={null} />
                            </div>
                        </div>
                        <BreakpointView
                            desktopChildren={null}
                            mobileChildren={(
                                <div className={classNames(styles[`ghost-component__${elementType}--text-container`], 'spacing--left')}>

                                    <div className={classNames(styles[`ghost-component__${elementType}--title`], hasShimmer ? 'shimmer' : '')} />
                                    <div className={classNames(styles[`ghost-component__${elementType}--info`], hasShimmer ? 'shimmer' : '')} />
                                    <div className={classNames(styles[`ghost-component__${elementType}--artists`], hasShimmer ? 'shimmer' : '')} />
                                </div>
                            )} />

                        <BreakpointView
                            desktopChildren={null}
                            mobileChildren={(
                                <div className={classNames(styles[`ghost-component__${elementType}--description`])}>
                                    <PlayButton type="play" isPlaying={false} onPress={() => null} removeHover fullWidth={isMobile} />
                                    <div className="spacing--left-half" />
                                    <SquaredButton onPress={() => null} removeHover fullWidth={isMobile} type="outline" leftIcon={<CustomIcon type="heart-outline-icon" />} label="Save" />
                                </div>
                            )} />
                    </>
                );
            case 'album-detail':
                return (
                    <>

                        <div className={styles[`ghost-component__${elementType}`]}>
                            <div className={classNames(styles[`ghost-component__${elementType}--image-container`])}>
                                <div className={classNames(styles[`ghost-component__${elementType}--image`], hasShimmer ? 'shimmer' : '')} />
                            </div>
                            <div className={classNames(styles[`ghost-component__${elementType}--text-container`])}>
                                <BreakpointView
                                    desktopChildren={(
                                        <div>
                                            <div className={classNames(styles[`ghost-component__${elementType}--title`], hasShimmer ? 'shimmer' : '')} />
                                            <div className={classNames(styles[`ghost-component__${elementType}--info`], hasShimmer ? 'shimmer' : '')} />
                                            <div className={classNames(styles[`ghost-component__${elementType}--artists`], hasShimmer ? 'shimmer' : '')} />
                                        </div>
                                    )}
                                    mobileChildren={null} />
                                <div className={classNames(styles[`ghost-component__${elementType}--description`])}>
                                    <PlayButton variant="round" type="play" isPlaying={false} onPress={() => null} removeHover fullWidth={isMobile} />
                                </div>
                            </div>
                        </div>
                        <BreakpointView
                            desktopChildren={null}
                            mobileChildren={(
                                <div className={classNames(styles[`ghost-component__${elementType}--mobile-text-container`], 'spacing--left')}>

                                    <div className={classNames(styles[`ghost-component__${elementType}--title`], hasShimmer ? 'shimmer' : '')} />
                                    <div className={classNames(styles[`ghost-component__${elementType}--info`], hasShimmer ? 'shimmer' : '')} />
                                    <div className={classNames(styles[`ghost-component__${elementType}--artists`], hasShimmer ? 'shimmer' : '')} />
                                </div>
                            )} />
                    </>
                );
            case 'for-you-detail':
                return (
                    <>

                        <div className={styles[`ghost-component__${elementType}`]}>
                            <div className={classNames(styles[`ghost-component__${elementType}--image-container`])}>
                                <div className={classNames(styles[`ghost-component__${elementType}--image`], hasShimmer ? 'shimmer' : '')} />
                            </div>
                            <div className={classNames(styles[`ghost-component__${elementType}--text-container`])}>
                                <BreakpointView
                                    desktopChildren={(
                                        <div>
                                            <div className={classNames(styles[`ghost-component__${elementType}--title`], hasShimmer ? 'shimmer' : '')} />
                                            <div className={classNames(styles[`ghost-component__${elementType}--info`], hasShimmer ? 'shimmer' : '')} />
                                            <div className={classNames(styles[`ghost-component__${elementType}--artists`], hasShimmer ? 'shimmer' : '')} />
                                        </div>
                                    )}
                                    mobileChildren={null} />
                                <div className={classNames(styles[`ghost-component__${elementType}--description`])}>
                                    <PlayButton variant="round" type="play" isPlaying={false} onPress={() => null} removeHover fullWidth={isMobile} />
                                </div>
                            </div>
                        </div>
                        <BreakpointView
                            desktopChildren={null}
                            mobileChildren={(
                                <div className={classNames(styles[`ghost-component__${elementType}--mobile-text-container`], 'spacing--left')}>

                                    <div className={classNames(styles[`ghost-component__${elementType}--title`], hasShimmer ? 'shimmer' : '')} />
                                    <div className={classNames(styles[`ghost-component__${elementType}--info`], hasShimmer ? 'shimmer' : '')} />
                                    <div className={classNames(styles[`ghost-component__${elementType}--artists`], hasShimmer ? 'shimmer' : '')} />
                                </div>
                            )} />
                    </>
                );
            case 'pack-detail':
                return (
                    <>
                        <div className={styles[`ghost-component__${elementType}`]}>
                            <div className={classNames(styles[`ghost-component__${elementType}--image-container`])}>
                                <div className={classNames(styles[`ghost-component__${elementType}--image`], hasShimmer ? 'shimmer' : '')} />
                            </div>
                            <div className={classNames(styles[`ghost-component__${elementType}--text-container`])}>
                                <BreakpointView
                                    desktopChildren={(
                                        <div className={classNames(styles[`ghost-component__${elementType}--info-container`])}>
                                            <div className={classNames(styles[`ghost-component__${elementType}--title`], hasShimmer ? 'shimmer' : '')} />
                                            <div className={classNames(styles[`ghost-component__${elementType}--info`], hasShimmer ? 'shimmer' : '')} />
                                            <div className={classNames(styles[`ghost-component__${elementType}--artists`], hasShimmer ? 'shimmer' : '')} />
                                            <div className={classNames(styles[`ghost-component__${elementType}--details`], hasShimmer ? 'shimmer' : '')} />

                                        </div>
                                    )}
                                    mobileChildren={null} />
                                <BreakpointView
                                    desktopChildren={(
                                        <div className={classNames(styles[`ghost-component__${elementType}--description`])}>
                                            <PlayButton type="demo" isPlaying={false} onPress={() => null} removeHover fullWidth={isMobile} />
                                            <div className="spacing--left-half" />
                                            <DownloadButton type="outline" onPress={() => null} />
                                        </div>
                                    )}
                                    mobileChildren={null} />
                            </div>
                        </div>
                        <BreakpointView
                            desktopChildren={null}
                            mobileChildren={(
                                <div className={classNames(styles[`ghost-component__${elementType}--text-container`])}>
                                    <div className={classNames(styles[`ghost-component__${elementType}--title`], hasShimmer ? 'shimmer' : '')} />
                                    <div className={classNames(styles[`ghost-component__${elementType}--info`], hasShimmer ? 'shimmer' : '')} />
                                    <div className={classNames(styles[`ghost-component__${elementType}--artists`], hasShimmer ? 'shimmer' : '')} />
                                    <div className={classNames(styles[`ghost-component__${elementType}--details`], hasShimmer ? 'shimmer' : '')} />
                                </div>
                            )} />

                        <BreakpointView
                            desktopChildren={null}
                            mobileChildren={(
                                <div className={classNames(styles[`ghost-component__${elementType}--description`])}>
                                    <PlayButton type="play" isPlaying={false} onPress={() => null} removeHover fullWidth={isMobile} />
                                    <div className="spacing--left-half" />
                                    <SquaredButton onPress={() => null} removeHover fullWidth={isMobile} type="outline" leftIcon={<CustomIcon type="heart-outline-icon" />} label="Save" />
                                </div>
                            )} />
                    </>
                );
            case 'curated-pack-detail':
                return (
                    <>
                        <div className={styles[`ghost-component__${elementType}`]}>
                            <div className={classNames(styles[`ghost-component__${elementType}--image-container`])}>
                                <div className={classNames(styles[`ghost-component__${elementType}--image`], hasShimmer ? 'shimmer' : '')} />
                            </div>
                            <div className={classNames(styles[`ghost-component__${elementType}--text-container`])}>
                                <BreakpointView
                                    desktopChildren={(
                                        <div>
                                            <div className={classNames(styles[`ghost-component__${elementType}--title`], hasShimmer ? 'shimmer' : '')} />
                                            <div className={classNames(styles[`ghost-component__${elementType}--info`], hasShimmer ? 'shimmer' : '')} />
                                            <div className={classNames(styles[`ghost-component__${elementType}--artists`], hasShimmer ? 'shimmer' : '')} />
                                        </div>
                                    )}
                                    mobileChildren={null} />
                                <BreakpointView
                                    desktopChildren={(
                                        <div className={classNames(styles[`ghost-component__${elementType}--description`])}>
                                            <DownloadButton type="dynamic" onPress={() => null} />
                                            <div className="spacing--left-half" />
                                            <SquaredButton onPress={() => null} removeHover fullWidth={isMobile} type="outline" leftIcon={<CustomIcon type="heart-outline-icon" />} label="Save" />
                                        </div>
                                    )}
                                    mobileChildren={null} />
                            </div>
                        </div>
                        <BreakpointView
                            desktopChildren={null}
                            mobileChildren={(
                                <div className={classNames(styles[`ghost-component__${elementType}--text-container`])}>
                                    <div className={classNames(styles[`ghost-component__${elementType}--title`], hasShimmer ? 'shimmer' : '')} />
                                    <div className={classNames(styles[`ghost-component__${elementType}--info`], hasShimmer ? 'shimmer' : '')} />
                                    <div className={classNames(styles[`ghost-component__${elementType}--artists`], hasShimmer ? 'shimmer' : '')} />
                                </div>
                            )} />

                        <BreakpointView
                            desktopChildren={null}
                            mobileChildren={(
                                <div className={classNames(styles[`ghost-component__${elementType}--description`])}>
                                    <SquaredButton onPress={() => null} removeHover fullWidth={isMobile} type="outline" leftIcon={<CustomIcon type="heart-outline-icon" />} label="Save" />
                                </div>
                            )} />
                    </>
                );
            case 'label-detail':
                return (
                    <>
                        <div className={styles[`ghost-component__${elementType}`]}>
                            <div className={classNames(styles[`ghost-component__${elementType}--image-container`])}>
                                <div className={classNames(styles[`ghost-component__${elementType}--image`], hasShimmer ? 'shimmer' : '')} />
                            </div>
                            <div className={classNames(styles[`ghost-component__${elementType}--text-container`])}>
                                <BreakpointView
                                    desktopChildren={(
                                        <div className={styles[`ghost-component__${elementType}--label-details`]}>
                                            <div className={classNames(styles[`ghost-component__${elementType}--packs`], hasShimmer ? 'shimmer' : '')} />
                                            <div className={classNames(styles[`ghost-component__${elementType}--title`], hasShimmer ? 'shimmer' : '')} />
                                            <TagsViewGhost large={false} />
                                            <div className={classNames(styles[`ghost-component__${elementType}--info`], hasShimmer ? 'shimmer' : '')} />
                                        </div>
                                    )}
                                    mobileChildren={null} />
                                <BreakpointView
                                    desktopChildren={(
                                        <div className={classNames(styles[`ghost-component__${elementType}--description`])}>
                                            <FollowButton type="label" isFollowed={false} />
                                            <div className="spacing--left-half" />
                                            <SquaredButton onPress={() => null} removeHover fullWidth={isMobile} type="outline" label="See All Packs" />
                                        </div>
                                    )}
                                    mobileChildren={null} />
                            </div>
                        </div>

                        <BreakpointView
                            desktopChildren={null}
                            mobileChildren={(
                                <div className={classNames(styles[`ghost-component__${elementType}--text-container`], 'spacing--horizontal')}>
                                    <div className={styles[`ghost-component__${elementType}--label-details`]}>
                                        <div className={classNames(styles[`ghost-component__${elementType}--packs`], hasShimmer ? 'shimmer' : '')} />
                                        <div className={classNames(styles[`ghost-component__${elementType}--title`], hasShimmer ? 'shimmer' : '')} />
                                        <TagsViewGhost large={false} />
                                        <div className={classNames(styles[`ghost-component__${elementType}--info`], hasShimmer ? 'shimmer' : '')} />
                                    </div>
                                    <div className={classNames(styles[`ghost-component__${elementType}--description`])}>
                                        <FollowButton type="label" isFollowed={false} />
                                        <div className="spacing--left-half" />
                                        <SquaredButton onPress={() => null} removeHover type="outline" label="See All Packs" />
                                    </div>
                                </div>
                            )} />
                    </>
                );
            case 'track-list':
                return (
                    <div className={classNames(noPadding ? '' : 'spacing__window--horizontal')}>
                        {title ? <SecondaryPageTitle title={title} noPadding /> : null}
                        {noPadding ? null : <div className="spacing--top" />}
                        {hasTrackListTabs ? (
                            <div className={classNames(styles['ghost-component__track-list--tabs'], hasShimmer ? 'shimmer' : '')} />
                        ) : null}

                        <BreakpointView
                            desktopChildren={isArtistPortal ? (
                                <ArtistPortalList preset={preset as ArtistPortalListPreset || ArtistPortalListPreset.DJType} list={[]} />
                            ) : isCreate ? (isCreatePreset === undefined || isCreatePreset === true) ? (
                                <TrackListCreatePresets list={[]} hideNotAvailableBlock />
                            ) : (
                                <TrackListCreate list={[]} hideNotAvailableBlock />
                            ) : (
                                <div className={styles['ghost-component__track-list--top']}>
                                    <TrackListSupreme
                                        list={[]}
                                        preset={preset as TrackListPresetSupreme}
                                        isSortable={false}
                                        isDraggable={false}
                                    />
                                </div>
                            )}
                            mobileChildren={null} />
                        <TrackListGhostLoading amount={elementsCount || 10} isMobile={isMobile} isCreate={isCreate} />
                    </div>
                );
            case 'track-list-item':
                return (
                    <div className={classNames(noPadding ? '' : 'spacing--horizontal')}>
                        <TrackListGhostLoading hasShimmer={hasShimmer} amount={elementsCount || 10} isMobile={isMobile} isCreate={isCreate} onlyListItem />
                    </div>
                );
            default:
                return (
                    <div className={styles['ghost-component']}>
                        <div className={classNames(styles['ghost-component__banner'], hasShimmer ? 'shimmer' : '')} />
                    </div>
                );
        }
    };

    return renderElement(type);
}
