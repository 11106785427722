import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Swiper as SwiperConfig } from 'swiper';
import { useUniqueArray } from '@bpm-web-app/utils';
import styles from './card-carousel.module.css';
import SecondaryPageTitle from '../secondary-page-title/secondary-page-title';
import CardGhostLoading, { CardGhostLoadingProps } from '../card/card-ghost-loading';
import CardGrid from '../card-grid/card-grid';
import { ArtistCardImageGhost } from '../artist-card-image/artist-card-image-ghost';

export interface CardCarouselGhostLoadingProps extends CardGhostLoadingProps {
    title?: string;
    elementsCount: number;
    grid?: boolean;
    noPadding?: boolean;
    isArtist?: boolean;
}

export function CardCarouselGhostLoading({ title, elementsCount, cardSize, linesWidthArray, lineHeight, grid, noPadding = false, isArtist }: CardCarouselGhostLoadingProps) {
    const [, setSwiper] = useState<SwiperConfig>();

    const ghostElementsKeys = useUniqueArray(elementsCount);

    const cardType = () => {
        switch (cardSize) {
            case 'label':
                return 'card-label';
            case 'large-square':
                return 'card-large';
            case 'large':
                return 'card-large';
            case 'medium':
                return 'card-medium';
            case 'small':
                return 'card-small';
            case 'user-playlist':
                return 'card-small';
            default:
                return 'card-small';
        }
    };

    return (
        <div className={noPadding ? '' : styles['card-carousel']}>
            {title && (
                <div className={styles['card-carousel__controls-container']}>
                    <SecondaryPageTitle title={title} noPadding={noPadding} />
                </div>
            )}
            {!grid ? (
                <Swiper className={noPadding ? '' : 'spacing--left-first-child'} modules={[Navigation]} spaceBetween={16} slidesPerView="auto" slidesPerGroupAuto enabled={false} onSwiper={setSwiper}>
                    {!isArtist ? (
                        ghostElementsKeys.map((uniqueKey) => (
                            <SwiperSlide key={uniqueKey}>
                                <CardGhostLoading cardSize={cardSize} linesWidthArray={linesWidthArray} lineHeight={lineHeight} />
                            </SwiperSlide>
                        ))
                    ) : (
                        ghostElementsKeys.map((uniqueKey) => (
                            <SwiperSlide key={uniqueKey}>
                                <ArtistCardImageGhost />
                            </SwiperSlide>
                        ))
                    )}
                </Swiper>
            ) : (
                <CardGrid cardType={cardType()}>
                    {ghostElementsKeys.map((uniqueKey) => (
                        <div key={uniqueKey}>
                            <CardGhostLoading cardSize={cardSize} linesWidthArray={linesWidthArray} lineHeight={lineHeight} />
                        </div>
                    ))}
                </CardGrid>
            )}
        </div>
    );
}
