import { Label } from '@bpm-web-app/api-client';
import useSWR from 'swr';

export function useSearchLabels(name: string) {
    const { data, error } = useSWR(
        [
            'create-search-labels',
            {
                persist: false,
                name,
            },
        ],
        () => Label.searchLabels(name)
    );

    return {
        data: data?.data,
        isLoading: !error && !data,
        error,
    };
}
