import { useGetFavoritedLabels } from '@bpm-web-app/swr-hooks';
import { createAppRoutes } from '@bpm-web-app/utils';
import SeeMoreLayout from '../see-more-layout/see-more-layout';

export function FavoriteLabels() {
    const { data } = useGetFavoritedLabels();

    return (
        <SeeMoreLayout
            contentType="label"
            pageTitle="Labels"
            count={data?.data?.length || 0}
            itemBasePath={createAppRoutes.labels}
            data={data?.data || []}
        />
    );
}

export default FavoriteLabels;
