import { ReactComponent as AlbumIcon } from '../../../assets/icons/artist-platform/sources/Album.svg';
import { ReactComponent as ArtistIcon } from '../../../assets/icons/artist-platform/sources/Artist.svg';
import { ReactComponent as CategoryIcon } from '../../../assets/icons/artist-platform/sources/Category.svg';
import { ReactComponent as CrateIcon } from '../../../assets/icons/artist-platform/sources/Crate.svg';
import { ReactComponent as AddToCrateIcon } from '../../../assets/icons/add-to-crate.svg';
import { ReactComponent as RemoveFromCrateIcon } from '../../../assets/icons/remove-from-crate-dynamic.svg';
import { ReactComponent as CuratedSetsIcon } from '../../../assets/icons/artist-platform/sources/CuratedSets.svg';
import { ReactComponent as ForYouIcon } from '../../../assets/icons/artist-platform/sources/ForYou.svg';
import { ReactComponent as GenreIcon } from '../../../assets/icons/artist-platform/sources/Genre.svg';
import { ReactComponent as MyPlaylistsIcon } from '../../../assets/icons/artist-platform/sources/MyPlaylists.svg';
import { ReactComponent as NewReleasesIcon } from '../../../assets/icons/artist-platform/sources/NewReleases.svg';
import { ReactComponent as PlaylistsIcon } from '../../../assets/icons/artist-platform/sources/Playlists.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/artist-platform/sources/Search.svg';
import { ReactComponent as TrendingIcon } from '../../../assets/icons/artist-platform/sources/Trending.svg';
import { ReactComponent as UnknownIcon } from '../../../assets/icons/artist-platform/sources/Unknown.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { ReactComponent as AddCircleIcon } from '../../../assets/icons/add.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-lg.svg';
import { ReactComponent as DeleteFilledIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as NewWindowIcon } from '../../../assets/icons/new-window.svg';
import { ReactComponent as CheckmarkIcon } from '../../../assets/icons/checkmark.svg';
import { ReactComponent as CheckmarkDefaultIcon } from '../../../assets/icons/check.svg';
import { ReactComponent as ClockIcon } from '../../../assets/icons/clock.svg';
import { ReactComponent as MinusIcon } from '../../../assets/icons/minus.svg';
import { ReactComponent as TrendingTracksIcon } from '../../../assets/icons/trending.svg';
import { ReactComponent as PlaylistIcon } from '../../../assets/icons/playlist-item.svg';
import { ReactComponent as FavoriteHeartIcon } from '../../../assets/icons/favorite-heart-outline.svg';
import { ReactComponent as FavoriteHeartFilledIcon } from '../../../assets/icons/favorite-heart.svg';
import { ReactComponent as BPMExclusiveIcon } from '../../../assets/icons/exclusive-tag.svg';
import { ReactComponent as NewReleaseIcon } from '../../../assets/icons/new-release.svg';
import { ReactComponent as MIDIIcon } from '../../../assets/icons/create/midi.svg';
import { ReactComponent as PresetsIcon } from '../../../assets/icons/create/presets.svg';
import { ReactComponent as ThreeDotsIcon } from '../../../assets/icons/more-dots-vert.svg';
import { ReactComponent as FollowActiveIcon } from '../../../assets/icons/follow-active.svg';
import { ReactComponent as FollowIcon } from '../../../assets/icons/follow.svg';
import { ReactComponent as ShareArrowIcon } from '../../../assets/icons/share-small.svg';
import { ReactComponent as ShareIcon } from '../../../assets/icons/share-link.svg';
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-right.svg';
import { ReactComponent as LinkIcon } from '../../../assets/icons/artist-platform/link.svg';
import { ReactComponent as PlayIcon } from '../../../assets/icons/player-play.svg';
import { ReactComponent as PauseIcon } from '../../../assets/icons/player-pause.svg';
import { ReactComponent as AddToQueue } from '../../../assets/icons/add-to-queue-lg.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import { ReactComponent as EditPencilOutlineIcon } from '../../../assets/icons/edit-pencil-outline.svg';
import { ReactComponent as EditPencilIcon } from '../../../assets/icons/pencil.svg';
import { ReactComponent as RoundPlayIcon } from '../../../assets/icons/player-play-alt.svg';
import { ReactComponent as RoundPauseIcon } from '../../../assets/icons/player-pause-lg.svg';
import { ReactComponent as ProfileIcon } from '../../../assets/icons/profile-icon-default.svg';
import { ReactComponent as EmailIcon } from '../../../assets/icons/email.svg';
import { ReactComponent as CircleCrossIcon } from '../../../assets/icons/circle-cross.svg';
import { ReactComponent as CircleMinusIcon } from '../../../assets/icons/circle-minus.svg';
import { ReactComponent as SharedFolderIcon } from '../../../assets/icons/folder-shared.svg';
import { ReactComponent as FolderIcon } from '../../../assets/icons/folder.svg';
import { ReactComponent as CardIcon } from '../../../assets/icons/card.svg';
import { ReactComponent as ChevronRightIcon } from '../../../assets/icons/chevron-right.svg';
import { ReactComponent as SortIcon } from '../../../assets/icons/sort.svg';
import { ReactComponent as SharedWithMe } from '../../../assets/icons/shared-with-me.svg';
import { ReactComponent as NotificationBellIcon } from '../../../assets/icons/notification-bell.svg';
import { ReactComponent as NotificationBellActiveIcon } from '../../../assets/icons/notification-bell-active.svg';
import { ReactComponent as WarningIcon } from '../../../assets/icons/icon-warning.svg';
import { ReactComponent as BPMSupremeLogo } from '../../../assets/icons/bpm-logo.svg';
import { ReactComponent as InformationCircleIcon } from '../../../assets/icons/info-circle-i.svg';
import { ReactComponent as AddToPlaylistIcon } from '../../../assets/icons/add-to-playlist.svg';
import { ReactComponent as AddToPlaylistLargeIcon } from '../../../assets/icons/add-to-playlist-lg.svg';
import { ReactComponent as FindSimilarIcon } from '../../../assets/icons/find-similar.svg';
import { ReactComponent as FeaturedInIcon } from '../../../assets/icons/featured-in.svg';
import { ReactComponent as SwitchPlatformIcon } from '../../../assets/icons/switch-platform.svg';
import { ReactComponent as VolumeIcon } from '../../../assets/icons/player-volume.svg';
import { ReactComponent as VolumeMuteIcon } from '../../../assets/icons/player-volume-off.svg';
import { ReactComponent as FiltersIcon } from '../../../assets/icons/filters.svg';
import { ReactComponent as LockPremiumIcon } from '../../../assets/icons/lock-premium-icon.svg';
import { ReactComponent as SoundWaveIcon } from '../../../assets/icons/sounds-wave-icon.svg';

const BPMIcons = {
    AlbumIcon,
    ArtistIcon,
    CategoryIcon,
    CrateIcon,
    AddToCrateIcon,
    RemoveFromCrateIcon,
    CuratedSetsIcon,
    ForYouIcon,
    GenreIcon,
    MyPlaylistsIcon,
    NewReleasesIcon,
    PlaylistsIcon,
    SearchIcon,
    TrendingIcon,
    UnknownIcon,
    PlusIcon,
    CloseIcon,
    DeleteIcon,
    MinusIcon,
    CheckmarkIcon,
    CheckmarkDefaultIcon,
    ClockIcon,
    TrendingTracksIcon,
    PlaylistIcon,
    FavoriteHeartIcon,
    FavoriteHeartFilledIcon,
    BPMExclusiveIcon,
    NewReleaseIcon,
    MIDIIcon,
    PresetsIcon,
    ThreeDotsIcon,
    FollowActiveIcon,
    FollowIcon,
    ShareIcon,
    ShareArrowIcon,
    ArrowRight,
    LinkIcon,
    PlayIcon,
    PauseIcon,
    AddToQueue,
    DownloadIcon,
    EditPencilOutlineIcon,
    RoundPlayIcon,
    RoundPauseIcon,
    ProfileIcon,
    EmailIcon,
    CircleCrossIcon,
    CircleMinusIcon,
    SharedFolderIcon,
    FolderIcon,
    CardIcon,
    ChevronRightIcon,
    AddCircleIcon,
    EditPencilIcon,
    DeleteFilledIcon,
    NewWindowIcon,
    SortIcon,
    SharedWithMe,
    NotificationBellIcon,
    NotificationBellActiveIcon,
    WarningIcon,
    BPMSupremeLogo,
    InformationCircleIcon,
    AddToPlaylistIcon,
    AddToPlaylistLargeIcon,
    FindSimilarIcon,
    SwitchPlatformIcon,
    VolumeIcon,
    VolumeMuteIcon,
    FeaturedInIcon,
    FiltersIcon,
    LockPremiumIcon,
    SoundWaveIcon,
};

export default BPMIcons;
