import { useGetFeaturedSoundPackages, useGetLabels, useInfiniteSearchPresets, useTags, useTrending } from '@bpm-web-app/swr-hooks';
import { convertSoundPackageToCarouselProps, createAppRoutes, DEFAULT_BROWSE_TRENDING_LIMIT, getMutatedPresets, soundPackageToCreatePlayable, PresetResults, useCreateFilterParams } from '@bpm-web-app/utils';
import { useCallback, useContext, useMemo } from 'react';
import { useRouter } from 'next/router';
import { TrendingByPeriodQuery } from '@bpm-web-app/api-client';
import { SoundPackage } from '@bpm-web-app/create-api-sdk';
import classNames from 'classnames';
import PrimaryPageTitle from '../../shared/primary-page-title/primary-page-title';
import styles from './presets.module.css';
import { TagsView } from '../../tags-view/tags-view';
import { Divider } from '../../divider/divider';
import { ClickableGenres } from '../../clickable-genres/clickable-genres';
import { CreateCardCarousel } from '../../create-card-carousel/create-card-carousel';
import { useCreatePlayer } from '../../create-player/create-player';
import { FiltersContext } from '../../filters/filters.context';
import { AppLink } from '../../shared/app-link/app-link';
import Trending, { ITrendingItem } from '../../trending/trending';
import TrackListCreatePresets from '../../shared/track-list/create-presets/track-list-create-presets';
import { GhostComponent } from '../../shared';
import SeeMore from '../../shared/see-more-button/see-more-btn';

export function Presets() {
    const { tags, setTags, synth, setSynth } = useContext(FiltersContext);
    const query = useCreateFilterParams({ type: 'preset', limit: 10 });
    const router = useRouter();

    const { data: presetData, mutate, isLastPage, isLoadingInitialData: isLoadingInitialDataPreset } = useInfiniteSearchPresets(query);
    const { tags: possibleTags, isLoading: isLoadingTags } = useTags({ ...query, limit: undefined });
    const { data: featuredSoundsData, isLoading: isLoadingFeaturedSounds } = useGetFeaturedSoundPackages({ limit: 20, type: 'preset', synth });
    const { playDemo } = useCreatePlayer();
    const { data: trendingData, isLoading } = useTrending('create', { limit: DEFAULT_BROWSE_TRENDING_LIMIT, skip: 0, type: 'preset', synth } as TrendingByPeriodQuery);
    const { data: labels, isLoading: isLoadingLabels } = useGetLabels(false, 'preset', synth);

    const soundPackages = useMemo(() => (trendingData?.data || []) as SoundPackage[], [trendingData?.data]);

    const isEverythingLoaded = useMemo(() => {
        if (
            !isLoadingInitialDataPreset &&
            !isLoadingTags &&
            !isLoadingFeaturedSounds &&
            !isLoadingLabels &&
            !isLoading
        ) return true;
        return false;
    }, [isLoading, isLoadingFeaturedSounds, isLoadingInitialDataPreset, isLoadingLabels, isLoadingTags]);

    const trending = useMemo(
        () =>
            soundPackages.map(
                (pack): ITrendingItem => ({
                    artist: pack.artist,
                    cover_url: pack.artwork_url,
                    trending_status: 'trending_status' in pack ? (pack as any).trending_status : 0,
                    id: pack.id,
                    title: pack.name,
                    slug: pack.slug,
                    genre: pack?.Genre,
                    tracking_id: pack.id
                })
            ),
        [soundPackages]
    );

    const labelCarouselData = useMemo(
        () =>
            labels?.map((label) => ({
                id: label.id,
                title: label.name,
                coverUrl: label.logo_url,
                slug: label.name,
            })) || [],
        [labels]
    );

    const featuredCarouselData = useMemo(
        () =>
            featuredSoundsData?.data.map((data) =>
                convertSoundPackageToCarouselProps(
                    data,
                    <div className="card__darker-padded-description">
                        <ClickableGenres genres={data.Genre} />
                    </div>
                )
            ) || [],
        [featuredSoundsData?.data]
    );

    const mutatePreset = useCallback(
        (id: string, progress: number) => {
            mutate(getMutatedPresets(presetData as PresetResults[], id, progress));
        },
        [mutate, presetData]
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const presets = useMemo(() => presetData?.flatMap((data) => data!.data), [presetData]);

    const possibleTagsString = useMemo(() => possibleTags?.map(({ name }) => name) || [], [possibleTags]);

    return (
        // eslint-disable-next-line @typescript-eslint/dot-notation
        <>
            <div className="spacing__window--top">
                {isEverythingLoaded ? (
                    <div className="spacing__window--horizontal">
                        <div className={styles['preset__header']}>
                            <PrimaryPageTitle title="Synth Presets" noPadding tooltip="Synth presets are playable instrument presets you can use with popular virtual instruments like Serum and Sylenth1." />
                        </div>
                        <TagsView
                            className={styles['preset__tag-view']}
                            large
                            tags={['Serum', 'Sylenth1', 'Massive', 'Spire']}
                            selected={synth ? [synth] : []}
                            orderBySelected={false}
                            onToggleTag={(tag, on) => {
                                if (on) {
                                    setSynth(tag);
                                } else {
                                    setSynth(undefined);
                                }
                            }} />
                    </div>
                ) : (
                    <>
                        <PrimaryPageTitle title="Synth Presets" tooltip="Synth presets are playable instrument presets you can use with popular virtual instruments like Serum and Sylenth1." />
                        <div className="spacing--top" />
                        <GhostComponent type="tags" largeTags key="tags-view-ghost" />
                        <div className="spacing--top" />

                    </>
                )}
                <Divider />
                <CreateCardCarousel
                    contentType="pack"
                    items={featuredSoundsData?.data || []}
                    cardSize="small"
                    carouselMorePath={{ pathname: createAppRoutes.featured, query: { fileType: 'preset', synth } }}
                    isLoading={!isEverythingLoaded}
                    carouselTitle="Featured Preset Packs"
                />
                <div className={classNames('spacing__window--horizontal')}>
                    <TrackListCreatePresets
                        mutateSound={mutatePreset}
                        isLoading={!isEverythingLoaded}
                        list={presets}
                        title="Newest Presets"
                        showTagFilter
                        tags={possibleTagsString}
                        selectedTags={tags}
                        onToggleTag={(tag, on) => {
                            if (on) {
                                setTags([...tags, tag]);
                            } else {
                                setTags(tags.filter((t) => t !== tag));
                            }
                        }} />

                    {!isLastPage && (
                        <AppLink href={{ pathname: createAppRoutes.presetsSeeMore, query: router.query }}>
                            <div className="spacing--bottom"><SeeMore expand={false} variant="text" /></div>
                        </AppLink>
                    )}
                </div>
                <Trending
                    trending={trending}
                    isLoading={!isEverythingLoaded}
                    platform="create"
                    className={styles['preset__trending']}
                    seeMorePath={{ pathname: createAppRoutes.trending, query: { fileType: 'preset', synth } }}
                />

            </div>
            <CreateCardCarousel
                contentType="label"
                items={labels || []}
                cardSize="small"
                carouselMorePath={{ pathname: createAppRoutes.labels, query: { fileType: 'preset', synth } }}
                isLoading={!isEverythingLoaded}
                carouselTitle="Labels"
            />
        </>
    );
}

export default Presets;
