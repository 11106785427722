import qs from 'query-string';
/** Create app routing paths */
export const createAppRoutes = {
    curated: '/curated',
    curatedSet: (slug: string) => `/curated/${encodeURIComponent(slug)}`,
    favorites: '/favorites',
    featured: '/sound-packages/featured',
    newFeatured: '/sound-packages/new/featured',
    freeSounds: '/sound-packages/free',
    freeSoundsAllPacks: '/sound-packages/free/all',
    freeSoundsAllCuratedPacks: '/sound-packages/free/all-curated',
    genres: '/genres',
    genresSlug: (slug: string) => `/genres/${encodeURIComponent(slug)}`,
    genresSlugWithSubgenre: (genre: string, subgenre: string) => `/genres/${encodeURIComponent(genre)}?subgenre=${encodeURIComponent(subgenre)}`,
    genresSlugPacks: (genre: string, query?: Record<string, any>) => (query ? `/genres/${encodeURIComponent(genre)}/packs?${qs.stringify(query)}` : `/genres/${encodeURIComponent(genre)}/packs`),
    genresSlugPacksWithSubgenre: (genre: string, subgenre: string, query?: Record<string, any>) =>
        query
            ? `/genres/${encodeURIComponent(genre)}/packs?subgenre=${encodeURIComponent(subgenre)}&${qs.stringify(query)}`
            : `/genres/${encodeURIComponent(genre)}/packs?subgenre=${encodeURIComponent(subgenre)}`,
    instruments: '/instruments',
    instrumentsSlug: (slug: string) => `/instruments/${slug}`,
    instrumentsSlugWithTag: (slug: string, tag: string) => `/instruments/${slug}?tag=${tag}`,
    instrumentsSlugPacks: (slug: string, query?: Record<string, any>) => (query ? `/instruments/${slug}/packs?${qs.stringify(query)}` : `/instruments/${slug}/packs`),
    instrumentsSlugPacksWithTag: (slug: string, tag: string, query?: Record<string, any>) =>
        query ? `/instruments/${slug}/packs?tag=${tag}&${qs.stringify(query)}` : `/instruments/${slug}/packs?tag=${tag}`,
    labels: '/labels',
    labelNewestPacks: (name: string) => `/labels/${name}/newest-packs`,
    labelTopPacks: (name: string) => `/labels/${encodeURIComponent(name)}/top-packs`,
    labelDetail: (name: string) => `/labels/${encodeURIComponent(name)}`,
    labelPacks: (name: string) => `/label-packs/${encodeURIComponent(name)}`,
    midi: '/midi',
    midiSeeMore: '/midi/more',
    presets: '/presets',
    presetsSeeMore: '/presets/more',
    myDrive: '/my-drive',
    myDriveId: (id: string) => `/my-drive/${id}`,
    newestPacks: '/sound-packages/new',
    packs: '/packs',
    packsSlug: (slug: string) => `/packs/${encodeURIComponent(slug)}`,
    contestDetail: (slug: string) => `/contests/${encodeURIComponent(slug)}`,
    search: '/search',
    suggestions: '/suggestions',
    trending: '/trending',
    accountDownloadPacks: '/account/downloads/packs',
    contest: '/contest',
    contests: '/contests',
    favoritesPacks: '/favorites/packs',
    favoritesSounds: '/favorites/sounds',
    favoritesPresets: '/favorites/presets',
    favoriteLabels: '/favorites/labels',
    favoriteCurated: '/favorites/curated',
    similarPacks: (id: string) => `/similar-packs/${encodeURIComponent(id)}`,
    similarSounds: (slug: string) => `/similar-sounds/${encodeURIComponent(slug)}`,
    section: (sectionName: string, location: string, type: 'curated' | 'soundPackage') => `/section/${encodeURIComponent(sectionName)}?type=${type}&location=${location}`,
};
