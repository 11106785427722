import { Analytics, appendQueryParams, createAppRoutes, fileDownload, showToast, soundPackageToCreatePlayable, useApiErrorHandler, useDownloadPreview } from '@bpm-web-app/utils';
import { SoundPackage } from '@bpm-web-app/create-api-sdk';
import { useCreateLike, useDownloadSoundPackage, useDownloadSoundPackagePreview, useGetCredits } from '@bpm-web-app/swr-hooks';
import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { useCreatePlayer } from '../../../create-player/create-player';
import { CardCreate } from '../card-create';
import { CardDescriptionCreate } from '../card-description-create/card-description-create';
import styles from './pack-card-create.module.css';
import UpArrow from '../../../../assets/icons/create/up-arrow.svg';
import DownArrow from '../../../../assets/icons/create/down-arrow.svg';

export interface PackCardCreateProps {
    item: SoundPackage;
    items: SoundPackage[];
    showTrendingTags?: boolean;
    index?: number;
}

export function PackCardCreate({
    item,
    items,
    showTrendingTags,
    index,
}: PackCardCreateProps) {
    const { playDemo } = useCreatePlayer();
    const { isLiked, likeDislike } = useCreateLike('soundPackage');
    const downloadPack = useDownloadSoundPackage();
    const downloadPackPreview = useDownloadSoundPackagePreview();
    const router = useRouter();
    const { mutate: refreshCredits } = useGetCredits();
    const errorHandler = useApiErrorHandler();

    const {
        closeDownloadModal,
        setShowDownloadPreviewModal,
    } = useDownloadPreview();

    const handleDownloadUrl = useCallback(
        (url: string) => {
            fileDownload(url);
            refreshCredits();
        },
        [refreshCredits]
    );

    const handleDownloadPack = useCallback(async () => {
        closeDownloadModal();
        try {
            const response = await downloadPack(item.id, router.asPath);
            if (response && response.data && response.data.url) handleDownloadUrl(response.data.url);
            if (item.id) {
                Analytics.trackClick('download_pack', item.id, { location: 'pack-card' });
            }
        } catch (err) {
            errorHandler({ error: err });
        }
    }, [closeDownloadModal, downloadPack, errorHandler, handleDownloadUrl, item.id, router.asPath]);

    const onDownload = useCallback(async () => {
        await showToast({
            promise: handleDownloadPack(),
            message: 'Download in progress.',
            successText: 'Download successful.',
            preventErrorToast: true
        });
    }, [handleDownloadPack]);

    const handleDownloadPreview = useCallback(async () => {
        try {
            const response = await downloadPackPreview(item.id);
            const creditsRequired = response?.data?.required?.total;
            let subtitle = '';

            if (creditsRequired && creditsRequired > 0) {
                if (item.sound_count === 1) subtitle = 'this file';
                else if (item.sound_count > 1) subtitle = 'these files';
                setShowDownloadPreviewModal({
                    subtitle,
                    amount: creditsRequired,
                    onCancel: () => closeDownloadModal,
                    onConfirm: () => onDownload
                });
            } else {
                onDownload();
            }
        } catch (err) {
            errorHandler({ error: err });
        }
    }, [downloadPackPreview, item.id, item.sound_count, setShowDownloadPreviewModal, closeDownloadModal, onDownload, errorHandler]);

    const getTrendingStatus = (trendingStatus: number, currentIndex: number) => {
        if (trendingStatus > 0) {
            return (
                <div className={styles['pack-card-create__status-container']}>
                    <span className={classNames(styles['pack-card-create__status-text'], styles['pack-card-create__status-text--up'])}>{currentIndex > 9 ? currentIndex : `0${currentIndex}`}</span>
                    <UpArrow />
                </div>
            );
        }

        return (
            <div className={styles['pack-card-create__status-container']}>
                <span className={classNames(styles['pack-card-create__status-text'], styles['pack-card-create__status-text--down'])}>{currentIndex > 9 ? currentIndex : `0${currentIndex}`}</span>
                <DownArrow />
            </div>
        );
    };

    const packDescription = useMemo(() => {
        return item ? (
            <CardDescriptionCreate
                soundsCount={item.sound_count}
                midiCount={item.midi_count}
                presetsCount={item.preset_count}
                descriptionLabel={item.Label ? item.Label.name : undefined}
                descriptionHref={item.Label ? `/labels/${item.Label.name}` : undefined}
                descriptionId={item.Label ? item.Label.id : undefined}
                genres={[item.Genre]}
            />
        ) : null;
    }, [item]);

    return (
        <CardCreate
            contentType="pack"
            cardSize="small"
            key={item.id}
            id={`${item.id}`}
            title={item.name}
            approved={item.approved}
            imageUrl={appendQueryParams(item.artwork_url, { key: 'dw', value: 160 })}
            imageUrl2x={appendQueryParams(item.artwork_url, { key: 'dw', value: 320 })}
            description={packDescription}
            onFavorite={likeDislike}
            isFavorite={isLiked(item.id)}
            onCardPlay={item.demo_file_url ? (id) => playDemo(soundPackageToCreatePlayable(item)) : undefined}
            tag={showTrendingTags && (item as any).trending_status ? getTrendingStatus((item as any).trending_status, (index || 0) + 1) : undefined}
            link={createAppRoutes.packsSlug(item.slug)}
            hasExtraDemo={!!item.demo_file_url_2}
            badge={showTrendingTags ? undefined : item.badge}
            items={items}
            onDownload={handleDownloadPreview}
        />
    );
}
