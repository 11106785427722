import classNames from 'classnames';
import { useGetLabels } from '@bpm-web-app/swr-hooks';
import { useCreateFilterParams } from '@bpm-web-app/utils';
import { Fragment, useMemo, useState } from 'react';
import CardGrid from '../../shared/card-grid/card-grid';
import Banner from '../../shared/banner/banner';
import PrimaryPageTitle from '../../shared/primary-page-title/primary-page-title';
import styles from './labels.module.css';
import { GhostComponent } from '../../shared';
import { LabelCardCreate } from '../../shared/card/label-card-create/label-card-create';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LabelsProps { }

export function Labels(props: LabelsProps) {
    const { type, synth } = useCreateFilterParams();
    const { data: labels, isLoading } = useGetLabels(false, type, synth);
    const [isBannersLoaded, setIsBannersLoaded] = useState(false);
    const [searchQuery, setSearchQuery] = useState<string>('');

    const isEverythingLoaded = useMemo(() => {
        if (!isLoading &&
            isBannersLoaded
        ) return true;
        return false;
    }, [isBannersLoaded, isLoading]);

    const filteredLabels = useMemo(() => labels?.filter((label) => label.name.toLowerCase().includes(searchQuery.toLowerCase())) || [], [labels, searchQuery]);

    return (
        // eslint-disable-next-line @typescript-eslint/dot-notation
        <section className={styles['labels']}>
            <div className={styles['labels__reversed-header']}>
                <div className={classNames(styles['labels__header'])}>
                    <PrimaryPageTitle title="Labels" noPadding />
                    <div
                        className={classNames(styles['labels__search'], {
                            [styles['labels__search--has-value']]: !!searchQuery,
                        })}
                    >
                        <input type="text" onChange={(e) => setSearchQuery(e.target.value)} placeholder="Search Labels" value={searchQuery} className={styles['labels__search-input']} />
                        {!!searchQuery && (
                            <button type="button" onClick={() => setSearchQuery('')} className={styles['labels__search-clear']}>
                                <i className="icon icon--controls-clear" />
                            </button>
                        )}
                    </div>
                </div>
                <Banner variant="hidden" platform="create" location="labels" isDataLoaded={isEverythingLoaded} setIsDataLoaded={setIsBannersLoaded} />
            </div>

            {isEverythingLoaded ? (
                <div className={styles['labels__grid-container']}>
                    <CardGrid cardType="card-small">
                        {filteredLabels.map((label) => (
                            <Fragment key={label.id}>
                                <LabelCardCreate item={label} items={filteredLabels} />
                            </Fragment>
                        ))}
                    </CardGrid>
                </div>
            ) : (
                <div className={classNames('spacing--horizontal')}><GhostComponent type="cards" cardSize="small" elementsCount={20} grid /></div>
            )}
        </section>
    );
}
