import { ActionModalProps } from '@bpm-web-app/components/src/lib/shared/action-modal/action-modal';
import { MediaWithAlbum } from '@bpm-web-app/stream-api-sdk';
import { Album, CuratedSet, Playlist } from '@bpm-web-app/download-api-sdk';
import { createContext, ReactNode, useMemo, useState, useContext, useCallback } from 'react';
import { Sound, SoundPackage, CuratedSet as CuratedSoundPack, Label } from '@bpm-web-app/create-api-sdk';

export interface SignUpModalProps {
    type: 'full-access' | 'track' | 'playlist' | 'curated-set' | 'sound' | 'pack' | 'label' | 'preset';
    actionModalProps?: ActionModalProps;
}

export type CreateCardCarouselItem = {
    id: string;
    title: string;
    coverUrl: string;
    isExclusive?: boolean;
    slug: string;
    description?: string | ReactNode;
    label?: string;
    hasExtraDemo?: boolean;
    isPlayable?: boolean;
    submission_deadline?: string;
    approved?: boolean
    badge?: SoundPackage.BadgeEnum
};

export type SignUpModalMediaTypes = MediaWithAlbum | Album | Playlist | CreateCardCarouselItem | Sound | CuratedSet | CuratedSoundPack | SoundPackage | Label;
export interface UserSettingsContextProperties {
    isAnonymous: boolean;
    setIsAnonymous: (anonymous: boolean) => void;
    isShowingSignUpModal: boolean;
    closeSignUpModal: () => void;
    setShowSignUpModal: (options: SignUpModalProps) => void;
    modalType: SignUpModalProps['type'];
    actionModalProps?: ActionModalProps;
    setSelectedMedia: (newMediaCenter: SignUpModalMediaTypes, media?: SignUpModalMediaTypes[]) => void;
    mediaMostLeft?: SignUpModalMediaTypes;
    mediaLeft?: SignUpModalMediaTypes;
    mediaCenter?: SignUpModalMediaTypes;
    mediaRight?: SignUpModalMediaTypes;
    mediaMostRight?: SignUpModalMediaTypes;
}

export const UserSettingsContext = createContext<UserSettingsContextProperties>({} as UserSettingsContextProperties);

export interface UserSettingsProviderProps {
    children: ReactNode;
}

export function UserSettingsProvider({ children }: UserSettingsProviderProps) {
    const [isAnonymous, setIsAnonymous] = useState(false);

    // MARK: SIGN UP MODAL PROPS
    const [isShowingSignUpModal, setIsShowingSignUpModal] = useState(false);
    const [modalType, setMediaType] = useState<SignUpModalProps['type']>('full-access');
    const [actionModalProps, setActionModalProps] = useState<ActionModalProps | undefined>();
    const [mediaMostLeft, setMediaMostLeft] = useState<SignUpModalMediaTypes>();
    const [mediaLeft, setMediaLeft] = useState<SignUpModalMediaTypes>();
    const [mediaCenter, setMediaCenter] = useState<SignUpModalMediaTypes>();
    const [mediaRight, setMediaRight] = useState<SignUpModalMediaTypes>();
    const [mediaMostRight, setMediaMostRight] = useState<SignUpModalMediaTypes>();

    const close = useCallback(() => {
        setIsShowingSignUpModal(false);
    }, []);

    const value = useMemo<UserSettingsContextProperties>(
        () => ({
            isAnonymous,
            setIsAnonymous,
            isShowingSignUpModal,
            closeSignUpModal: close,
            setShowSignUpModal: ({
                type,
                actionModalProps: newActionModalProps
            }: SignUpModalProps) => {
                setIsShowingSignUpModal(true);
                setMediaType(type);
                setActionModalProps(newActionModalProps);
            },
            modalType,
            actionModalProps,
            setSelectedMedia: (newMediaCenter: SignUpModalMediaTypes, media?: SignUpModalMediaTypes[]) => {
                setMediaCenter(newMediaCenter);

                // MARK: Select left and right media albums given the center image.
                if (media as SignUpModalMediaTypes[] && media !== undefined && media.filter) {
                    const centerMediaId = newMediaCenter.id;
                    const seen = new Set();
                    const uniqueItems = media.filter((item) => {
                        if (!seen.has(item.id) && item.id !== centerMediaId) {
                            seen.add(item.id);
                            return true;
                        }
                        return false;
                    });
                    setMediaLeft(uniqueItems[0]);
                    setMediaRight(uniqueItems[1]);
                    setMediaMostLeft(uniqueItems[2]);
                    setMediaMostRight(uniqueItems[3]);
                } else {
                    setMediaLeft(undefined);
                    setMediaRight(undefined);
                    setMediaMostLeft(undefined);
                    setMediaMostRight(undefined);
                }
            },
            mediaMostLeft,
            mediaLeft,
            mediaCenter,
            mediaRight,
            mediaMostRight
        }),
        [actionModalProps, close, isAnonymous, isShowingSignUpModal, mediaCenter, mediaLeft, mediaMostLeft, mediaMostRight, mediaRight, modalType]
    );

    return <UserSettingsContext.Provider value={value}>{children}</UserSettingsContext.Provider>;
}

export function useUserSettings() {
    const context = useContext(UserSettingsContext);
    return context;
}
