import { CuratedSet, Label, Preset, Sound, SoundPackage } from '@bpm-web-app/create-api-sdk';
import { appendQueryParams, createAppRoutes } from '@bpm-web-app/utils';
import { ComponentProps, useCallback, useMemo } from 'react';
import { HorizontalTabs } from '../../../shared/ui/horizontal-tabs/horizontal-tabs';
import CreateListGridItem from '../../../shared/list-grid-item/create-list-grid-item';
import ListGrid from '../../../shared/ui/list-grid/list-grid';
import TrackListCreate from '../../../shared/track-list/create/track-list-create';
import styles from './favorites-mobile.module.css';
import TrackListCreatePresets from '../../../shared/track-list/create-presets/track-list-create-presets';
import { FavoriteEmptySpaceProps } from '../favorites-desktop/favorites-desktop';
import { EmptyState } from '../../../shared';
import { FAVORITE_EMPTY_STATES } from '../favorites-desktop/favorites-desktop-create';

interface FavoritesMobileCreateProps {
    soundPacks: SoundPackage[];
    soundPacksCount: number;
    sounds: Sound[];
    soundsCount: number;
    presets: Preset[];
    presetCount: number;
    curatedSets: CuratedSet[];
    curatedSetsCount: number;
    labels: Label[];
    labelsCount: number;
}

// eslint-disable-next-line max-len
export default function FavoritesMobileCreate({ soundPacks, sounds, presets, curatedSets, labels, soundPacksCount, soundsCount, presetCount, curatedSetsCount, labelsCount }: FavoritesMobileCreateProps) {
    const getNoResultsSection = useCallback(
        ({ emptyStateProps }: FavoriteEmptySpaceProps) => (
            <EmptyState {...emptyStateProps} actionIcon="chevron-right-icon" noPadding icon="heart-outline-icon" verticalMargins={16} />
        ), []
    );
    const mobileTabs = useMemo<ComponentProps<typeof HorizontalTabs>['tabs']>(
        () => [
            {
                id: 'packs',
                title: 'Packs',
                count: soundPacksCount || 0,
                hideNoResults: true,
                activeTabComponent: soundPacksCount > 0 ? (
                    <ListGrid>
                        {soundPacks?.map((item) => (
                            <CreateListGridItem
                                contentType="pack"
                                key={item.id}
                                id={item.id}
                                title={item.name}
                                imageUrl={appendQueryParams(item.artwork_url, { key: 'dw', value: 56 })}
                                imageUrl2x={appendQueryParams(item.artwork_url, { key: 'dw', value: 112 })}
                                subtitle={item.Genre.name}
                                link={createAppRoutes.packsSlug(item.slug)}
                                shareURL={createAppRoutes.packsSlug(item.slug)}
                                secondPlayableDemo={item.demo_file_url_2}
                            />
                        ))}
                    </ListGrid>
                ) : (
                    getNoResultsSection(FAVORITE_EMPTY_STATES[0])
                ),
            },
            {
                id: 'sounds',
                title: 'Sounds',
                count: soundsCount || 0,
                hideNoResults: true,
                activeTabComponent: soundPacksCount > 0 ? (
                    <div className={styles['free-sound-packs__mobile-track-list']}>
                        <TrackListCreate list={sounds} hideHeading />
                    </div>
                ) : (
                    getNoResultsSection(FAVORITE_EMPTY_STATES[1])
                ),
            },
            {
                id: 'presets',
                title: 'Synth Presets',
                count: presetCount || 0,
                hideNoResults: true,
                activeTabComponent: presetCount > 0 ? (
                    <div className={styles['free-sound-packs__mobile-track-list']}>
                        <TrackListCreatePresets list={presets} hideHeading />
                    </div>
                ) : (
                    getNoResultsSection(FAVORITE_EMPTY_STATES[2])
                ),
            },
            {
                id: 'curated',
                title: 'Curated Packs',
                count: curatedSetsCount || 0,
                hideNoResults: true,
                activeTabComponent: curatedSetsCount > 0 ? (
                    <ListGrid>
                        {curatedSets?.map((item) => (
                            <CreateListGridItem
                                contentType="curated-set"
                                key={item.id}
                                id={item.id}
                                title={item.name}
                                imageUrl={appendQueryParams(item.artwork_url, { key: 'dw', value: 56 })}
                                imageUrl2x={appendQueryParams(item.artwork_url, { key: 'dw', value: 112 })}
                                subtitle={undefined}
                                link={createAppRoutes.curatedSet(item.slug)}
                                shareURL={createAppRoutes.curatedSet(item.slug)}
                            />
                        ))}
                    </ListGrid>
                ) : (
                    getNoResultsSection(FAVORITE_EMPTY_STATES[3])
                ),
            },
            {
                id: 'Labels',
                title: 'Labels',
                count: labelsCount || 0,
                hideNoResults: true,
                activeTabComponent: labelsCount > 0 ? (
                    <ListGrid>
                        {labels?.map((item) => (
                            <CreateListGridItem
                                contentType="label"
                                key={item.id}
                                id={item.id}
                                title={item.name}
                                imageUrl={appendQueryParams(item.logo_url, { key: 'dw', value: 56 })}
                                imageUrl2x={appendQueryParams(item.logo_url, { key: 'dw', value: 112 })}
                                subtitle={item.top_genres}
                                link={createAppRoutes.labelDetail(item.name)}
                                shareURL={createAppRoutes.labelDetail(item.name)}
                                secondPlayableDemo={undefined}
                            />
                        ))}
                    </ListGrid>
                ) : (
                    getNoResultsSection(FAVORITE_EMPTY_STATES[4])
                ),
            },
        ],
        [soundPacksCount, soundPacks, getNoResultsSection, soundsCount, sounds, presetCount, presets, curatedSetsCount, curatedSets, labelsCount, labels]
    );

    return (
        <div className={styles['free-sound-packs__horizontal-tabs-container']}>
            <HorizontalTabs tabs={mobileTabs} />
        </div>
    );
}
