import { useSearchLabels } from '@bpm-web-app/swr-hooks';
import { appendQueryParams, convertTopGenresToString, createAppRoutes, useViewport } from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import { Fragment, useEffect, useMemo, useState } from 'react';
import CardGrid from '../../shared/card-grid/card-grid';
import { CreateCardCarousel } from '../../create-card-carousel/create-card-carousel';
import CreateListGridItem from '../../shared/list-grid-item/create-list-grid-item';
import ListGrid from '../../shared/ui/list-grid/list-grid';
import { NoResultsBlock } from '../../shared/ui/no-results-block/no-results-block';
import { SecondaryPageTitle } from '../../shared/secondary-page-title/secondary-page-title';

import styles from './search-labels.module.css';
import { GhostComponent } from '../../shared';
import SeeMore from '../../shared/see-more-button/see-more-btn';
import { LabelCardCreate } from '../../shared/card/label-card-create/label-card-create';

const SONGS_LINES_WIDTH = [80];
const DEFAULT_LABELS_TO_SHOW = 10;

export interface SearchLabelsProps {
    indexPage?: boolean;
    onLabelsCount: (count: number) => void;
    isLoadingData?: boolean;
    setIsLoadingData?: (isLoading: boolean) => void;
}

export function SearchLabels({ indexPage, onLabelsCount, isLoadingData, setIsLoadingData }: SearchLabelsProps) {
    const router = useRouter();

    const [itemsToShow, setItemsToShow] = useState(DEFAULT_LABELS_TO_SHOW);
    const { isMobile } = useViewport();

    const { searchTerm = '' } = router.query;

    const { data, isLoading } = useSearchLabels(`${searchTerm}`);

    useEffect(() => {
        onLabelsCount(data?.length);
    }, [data, onLabelsCount]);

    const seeMorePath = useMemo(() => {
        const seeMore = `${createAppRoutes.search}?type=labels`;

        // eslint-disable-next-line @typescript-eslint/dot-notation
        if (!router.query['searchTerm']) {
            return seeMore;
        }

        // eslint-disable-next-line @typescript-eslint/dot-notation
        return `${seeMore}&searchTerm=${router.query['searchTerm']}`;
    }, [router.query]);

    const items = useMemo(() => [...(data || [])].slice(0, itemsToShow), [data, itemsToShow]);

    useEffect(() => {
        if (setIsLoadingData) setIsLoadingData(isLoading);
    }, [isLoading, setIsLoadingData]);

    if (isLoadingData || isLoading) {
        return (
            <GhostComponent type="cards" elementsCount={10} cardSize="small" title="Labels" linesWidthArray={SONGS_LINES_WIDTH} lineHeight={10} />
        );
    }

    return (
        /* eslint-disable-next-line @typescript-eslint/dot-notation */
        <div className={styles['search-labels']}>
            {(!indexPage || !isMobile || (indexPage && isMobile && data?.length === 0)) && (
                <div className={styles['search-labels__intro-section']}>
                    {(data?.length === 0 || (!isMobile && indexPage)) && <SecondaryPageTitle title="Labels" counter={data?.length.toString()} />}
                    {data?.length === 0 && <div className="spacing--vertical"><NoResultsBlock hasPadding hasMargin>No Labels Available</NoResultsBlock></div>}
                </div>
            )}
            {data && data?.length > 0 ? (
                <div className={isMobile ? 'spacing--horizontal' : ''}>
                    {indexPage && isMobile && (
                        <ListGrid>
                            {items.map((label) => (
                                <Fragment key={label.id}>
                                    <CreateListGridItem
                                        key={label.id}
                                        id={label.id}
                                        title={label.name}
                                        imageUrl={appendQueryParams(label.logo_url, { key: 'dw', value: 56 })}
                                        imageUrl2x={appendQueryParams(label.logo_url, { key: 'dw', value: 112 })}
                                        subtitle={convertTopGenresToString(label?.top_genres)}
                                        link={createAppRoutes.labelDetail(label.name)}
                                        contentType="label"
                                    />
                                </Fragment>
                            ))
                            }
                        </ListGrid>
                    )}
                    {
                        indexPage && !isMobile && (
                            <CardGrid cardType="card-large">
                                {items.map((label) => (
                                    <Fragment key={`search-labels-card-${label.id}`}>
                                        <LabelCardCreate item={label} items={items} />
                                    </Fragment>
                                ))}
                            </CardGrid>
                        )
                    }

                    {
                        !indexPage && data && data.length > 0 && (
                            <CreateCardCarousel
                                contentType="label"
                                items={data || []}
                                cardSize="small"
                                carouselTitle="Labels"
                                carouselTitleCounter={data?.length.toString()}
                                carouselMorePath={seeMorePath}
                            />
                        )
                    }
                </div>
            ) : null}
            {
                indexPage && data && data.length > itemsToShow ? (
                    <div className={styles['search-labels__see-more']}><SeeMore expand={false} variant="text" onClick={() => setItemsToShow(itemsToShow + DEFAULT_LABELS_TO_SHOW)} /></div>
                ) : null
            }
        </div>
    );
}

export default SearchLabels;
