/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCurrentUserAddress, useGetPaymentInstrument, useGetSubscribePreview, useGetSubscriptionPackage, useStartSubscription } from '@bpm-web-app/swr-hooks';
import { PageNotFound } from '@bpm-web-app/components/pages';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { ChangeSubscriptionRequest, ChangeSubscriptionRequestStripe, ChangeSubscriptionResultStripe, CouponUsageCalc, GetMeWithSubscription, PaymentInstrumentStripe, SubscribePackageIdBody, SubscriptionMode, SubscriptionPackage, UserAddress } from '@bpm-web-app/supreme-api-sdk';
import { DOWNLOAD_ONLY, formatDateToString, getCurrentPlatformLink, getLibraryName, trackKlaviyoProduct, useApiErrorHandler, showToast, StripeCreatePromise, StripeSupremePromise, StripeOptionsCreate, StripeOptionsSupreme } from '@bpm-web-app/utils';
import { Subscription, User } from '@bpm-web-app/api-client';
import classNames from 'classnames';
import { Elements } from '@stripe/react-stripe-js';
import TiktokPixel from 'tiktok-pixel';
import CheckoutItem from './checkout-item/checkout-item';
import styles from './change.module.css';
import PromoCode from './promo-code/promo-code';
import ChevronRight from '../../../../assets/icons/chevron-right.svg';
import ReportSmallIcon from '../../../../assets/icons/report-small.svg';
import { ActionModal } from '../../../shared/action-modal/action-modal';
import { AppLink } from '../../../shared';
import AccountAddPayment from '../../account-add-payment/account-add-payment';

export interface AccountPlanUpgradeProps {
    isCreate?: boolean;
    isContinuePlanMode?: boolean;
    plan: SubscriptionPackage;
    currentSubscription: { data: GetMeWithSubscription, mutate: () => Promise<any> }
}

export function AccountPlanUpgradeV2({ isCreate, isContinuePlanMode, plan, currentSubscription }: AccountPlanUpgradeProps) {
    const router = useRouter();
    const { packageId } = router.query;
    const planId = Number(packageId);

    const [promoCode, setPromoCode] = useState<string>();
    const [previewPlanErrorMessage, setPreviewPlanErrorMessage] = useState<string>();
    const [previewPlanConfirmMessage, setPreviewPlanConfirmMessage] = useState<string>();

    const { data: userData, mutate: mutateUser } = currentSubscription;

    const { data: paymentInstrumentData, isLoading: isLoadingPaymentInstrument } = useGetPaymentInstrument(plan?.platform);

    const { data: oldPlan, isLoading: isLoadingOldPlan } = useGetSubscriptionPackage(userData.membership.subscription?.package_id);

    const { data: previewPlan, isLoading: isLoadingPreviewPlan, error: previewPlanError } = useGetSubscribePreview(planId, promoCode ? { coupon: promoCode } : undefined);

    const { data: userAddress, isLoading: isLoadingUserAddress } = useCurrentUserAddress();

    const errorHandler = useApiErrorHandler(isCreate);
    const startSubscriptionProcess = useStartSubscription();

    const [couponInfo, setCouponInfo] = useState<CouponUsageCalc>();
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
    const [paymentInstrument, setPaymentInstrument] = useState<PaymentInstrumentStripe>();

    const hasPlan = useMemo(() => userData.membership.has_membership, [userData.membership.has_membership]);

    const isChangingToPremium = useMemo(
        () => plan.name === SubscriptionPackage.NameEnum.Premium && oldPlan?.data?.name === SubscriptionPackage.NameEnum.Standard,
        [plan, oldPlan?.data?.name]
    );

    useEffect(() => {
        if (plan) {
            trackKlaviyoProduct(plan);
        }
    }, [plan]);

    useEffect(() => {
        if (paymentInstrumentData && paymentInstrumentData.data.method === PaymentInstrumentStripe.MethodEnum.Stripe) {
            setPaymentInstrument(paymentInstrumentData.data as PaymentInstrumentStripe);
        }
    }, [paymentInstrumentData]);

    useEffect(() => {
        if (paymentInstrument) {
            setIsSubmitButtonDisabled(false);
        } else {
            setIsSubmitButtonDisabled(true);
        }
    }, [paymentInstrument]);

    const isUpgrade = useMemo(() => isChangingToPremium || Number(plan?.amount) > Number(oldPlan?.data?.amount), [isChangingToPremium, plan?.amount, oldPlan?.data?.amount]);

    const isDowngrade = useMemo(() => Number(plan?.amount) < Number(oldPlan?.data?.amount), [plan?.amount, oldPlan?.data?.amount]);

    const hasPayment = useMemo(() => !!paymentInstrument, [paymentInstrument]);

    const isCreditsPurchase = useMemo(() => plan?.method === SubscriptionPackage.MethodEnum.OneTime, [plan?.method]);

    const handlerTitle = useMemo(() => {
        if (isContinuePlanMode) return 'Update Payment Method';
        if (isCreditsPurchase) return 'One-Time Credit Purchase';
        if (!hasPlan) return 'Add Plan';
        if (isUpgrade) return 'Upgrade Plan';
        if (isCreate && isDowngrade) return 'Downgrade Plan';
        return 'Switch Plan';
    }, [isContinuePlanMode, hasPlan, isCreate, isCreditsPurchase, isDowngrade, isUpgrade]);

    const handleParametersOnUrl = useMemo(() => {
        if (!hasPlan) {
            return '?new=true';
        }
        if (isUpgrade) {
            return `?upgrade=true&id=${plan?.id}`;
        }
        if (isCreditsPurchase) {
            return `?credit=true&id=${plan?.id}&credits=${plan?.amount_of_credits}`;
        }
        if (isDowngrade) {
            return `?downgrade=true&id=${plan?.id}`;
        }
        return `?switch=true&id=${plan?.id}`;
    }, [hasPlan, isCreditsPurchase, isDowngrade, isUpgrade, plan?.amount_of_credits, plan?.id]);

    useEffect(() => {
        if ((!isCreditsPurchase) && plan) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            if ((window as any).gtag && process.env['NEXT_PUBLIC_RELEASE_STAGE'] === 'production') {
                try {
                    (window as any).gtag('event', 'conversion', { send_to: 'AW-863988361/FcpPCPyU5OYDEInV_ZsD', type: 'subscription', platform: plan?.platform, library: plan?.library, amount: plan.amount });
                    // eslint-disable-next-line no-empty
                } catch (error) {

                }
            }
            try {
                TiktokPixel.track('CompletePayment', { platform: plan?.platform, library: plan?.library, value: plan.amount, currency: 'USD' });
                // eslint-disable-next-line no-empty
            } catch (error) {
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plan, isCreditsPurchase]);

    const handleNavigationOnSuccess = () => {
        router.push(getCurrentPlatformLink(`/account/plan${handleParametersOnUrl}`));
    };

    const handleUpdateAddress = async (data: UserAddress) => {
        await User.setMyCurrentAddress(data);
    };

    const handleErrorOnSubmit = async (error: any) => {
        if (error) {
            const errorData = error?.json && (await error.json());
            if (errorData?.error) {
                showToast({ type: 'error', message: errorData?.error?.message ?? 'Unavailable. Please try again later.' });
            }
        } else {
            errorHandler({ error });
        }
        setIsSubmitButtonDisabled(false);
    };

    const handlePlanSubscribeStripe = async () => {
        const body: SubscribePackageIdBody = {
            from: 'subscription',
            coupon: promoCode,
            payment_method_id: paymentInstrument?.id
        };
        try {
            const result = await Subscription.subscribeStripe(planId, body);
            if (!(result.data as ChangeSubscriptionResultStripe).success && (result.data as ChangeSubscriptionResultStripe).invoice_page) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                window.location.href = (result.data as ChangeSubscriptionResultStripe).invoice_page!;
            } else {
                await mutateUser();
                handleNavigationOnSuccess();
            }
        } catch (error: any) {
            await handleErrorOnSubmit(error);
        }
    };

    const handlePlanUpdate = async () => {
        if (previewPlan?.data.mode === SubscriptionMode.New) {
            return handlePlanSubscribeStripe();
        }

        const body: ChangeSubscriptionRequest = {
            payment_data: {
                method: ChangeSubscriptionRequestStripe.MethodEnum.Stripe,
                payment_method_id: paymentInstrument?.id
            } as ChangeSubscriptionRequestStripe,
            coupon: promoCode,
        };
        try {
            const response = await Subscription.changeSubscription(planId, body);
            if ((response?.data as any)?.type === 'paypal') { // ToDo
                router.push((response?.data as any)?.url);
            } else if ((response.data as ChangeSubscriptionResultStripe).type === ChangeSubscriptionResultStripe.TypeEnum.Stripe) {
                if (!(response.data as ChangeSubscriptionResultStripe).success && (response.data as ChangeSubscriptionResultStripe).invoice_page) {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    window.location.href = (response.data as ChangeSubscriptionResultStripe).invoice_page!;
                } else {
                    await mutateUser();
                    handleNavigationOnSuccess();
                }
            } else {
                await mutateUser();
                handleNavigationOnSuccess();
            }
        } catch (error: any) {
            await handleErrorOnSubmit(error);
        }
        return null;
    };

    const handleSubmit = async () => {
        setIsSubmitButtonDisabled(true);

        if (!paymentInstrument && ((!hasPlan) || (isCreditsPurchase))) {
            showToast({ type: 'success', message: 'Please add payment method.' });
        }
        if (hasPlan && !isCreditsPurchase) {
            await handlePlanUpdate();
        } else {
            await handlePlanSubscribeStripe();
        }
        setIsSubmitButtonDisabled(false);
    };

    useEffect(() => {
        async function fetchError() {
            if (previewPlanError?.json) {
                const error = (previewPlanError as Response);
                if (!error.bodyUsed) {
                    const errorData = await error?.json();
                    if (errorData?.error) {
                        setPreviewPlanErrorMessage(errorData?.error?.message ?? 'Unavailable. Please try again later.');
                        setIsSubmitButtonDisabled(true);
                    }
                }
            } else {
                setPreviewPlanErrorMessage(undefined);
            }
        }
        fetchError();
    }, [previewPlanError]);

    useEffect(() => {
        setPreviewPlanConfirmMessage(previewPlan?.data.confirm_message);
    }, [previewPlan?.data.confirm_message]);

    useEffect(() => {
        if (plan?.platform) {
            startSubscriptionProcess(plan?.platform).catch(() => null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plan]);

    const billingText = useCallback(() => {
        if (!previewPlan) {
            return '...';
        }
        if (!isCreditsPurchase && previewPlan.data.charged_now_amount > '0.00' && previewPlan.data.starts_now) {
            return `You will be charged $${previewPlan.data.charged_now_amount || '0.00'} now. Your next charge will be on ${formatDateToString(previewPlan.data.renews_on ?? userData.membership.subscription?.next_billing, 'MM/DD/YYYY')} when your plan renews at your ongoing rate.`;
        }
        if (isCreditsPurchase || previewPlan.data.charged_now_amount > '0.00') {
            return `You will be charged $${previewPlan.data.charged_now_amount || '0.00'} now.`;
        }
        return `You will be billed on ${formatDateToString(previewPlan.data.renews_on ?? userData.membership.subscription?.next_billing, 'MM/DD/YYYY')} when your new plan begins.`;
    }, [previewPlan, userData, isCreditsPurchase]);

    const newPlanTypeText = useMemo(() => {
        const period = plan?.info?.trial_allowed ? (plan.trial_period || plan.period) : plan.period;
        const term = (period > 1 && !isUpgrade && !isDowngrade) ? period === 12 ? '(1-Year)' : `(${period}-Months)` : '';
        if (isCreate || DOWNLOAD_ONLY) {
            return `${plan?.name.toString()} ${term}`.trim();
        }
        return `${plan?.platform} ${plan?.name} ${term}`.trim();
    }, [isCreate, plan, isUpgrade, isDowngrade]);

    if (packageId === undefined || Number.isNaN(+packageId)) return <PageNotFound />;

    if (isLoadingOldPlan || isLoadingPaymentInstrument || isLoadingPreviewPlan || isLoadingUserAddress) return null;

    return (
        <>
            {/* eslint-disable-next-line @typescript-eslint/dot-notation */}
            <div className={styles['change']}>
                <div className={styles['change__title']}>
                    <h2>{handlerTitle}</h2>
                </div>
                {isContinuePlanMode && (
                    // eslint-disable-next-line @typescript-eslint/dot-notation
                    <div className={styles['change__payment_failed']}>
                        {/* eslint-disable-next-line @typescript-eslint/dot-notation */}
                        <div className={styles['change__payment_failed__notif']}>
                            <ReportSmallIcon />
                            Your last payment didn’t go through, please update your payment method below.
                        </div>
                        {/* eslint-disable-next-line @typescript-eslint/dot-notation */}
                        <div className={styles['change__payment_failed__cancel']}>
                            or <AppLink href="/"><a className="relative-link">Stay on a Free Plan</a></AppLink>
                        </div>
                    </div>
                )}
                <div className={styles['change__plans']}>
                    {!isCreditsPurchase && !isContinuePlanMode && (
                        <>
                            {userData.membership.has_membership ? (
                                <CheckoutItem
                                    name="Current Plan"
                                    amount={Number(oldPlan?.data?.amount)}
                                    type={`${(isCreate || DOWNLOAD_ONLY) ? '' : oldPlan?.data?.platform} ${oldPlan?.data?.name}`}
                                    libraryText={getLibraryName(oldPlan?.data?.library)}
                                    library={oldPlan?.data?.library}
                                    additionalText={oldPlan?.data?.description || ''}
                                    isNew={false}
                                    isCreate={!!isCreate} />
                            ) : (
                                <CheckoutItem name="Current Plan" amount={0.0} type="Free" libraryText="N/A" additionalText="" isNew={false} isCreate={!!isCreate} />
                            )}
                            <div className={styles['change__plans-icon']}>
                                <ChevronRight />
                            </div>
                        </>
                    )}
                    <CheckoutItem
                        name={isCreditsPurchase ? '' : isContinuePlanMode ? 'Resume Plan' : 'New Plan'}
                        amount={Number(plan?.amount)}
                        type={newPlanTypeText}
                        libraryText={getLibraryName(plan?.library)}
                        library={plan?.library}
                        additionalText={plan?.description || ''}
                        promoCode={promoCode}
                        promoCodeDiscount={Number(previewPlan?.data?.coupon_info?.off_amount ?? couponInfo?.off_amount)}
                        trialDiscount={Number(plan?.info?.trial_discount)}
                        payToday={Number(previewPlan?.data?.charged_now_amount ?? plan?.amount)}
                        isCreate={!!isCreate}
                        isOneTime={isCreditsPurchase} />

                </div>
                {!isContinuePlanMode && (
                    <div className={styles['change__promo-code']}>
                        <PromoCode setPromoCode={setPromoCode} setCouponInfo={setCouponInfo} packageId={planId} />
                    </div>
                )}
                <Elements
                    stripe={isCreate ? StripeCreatePromise : StripeSupremePromise}
                    options={isCreate ? StripeOptionsCreate : StripeOptionsSupreme}
                >
                    {!isLoadingPaymentInstrument && <AccountAddPayment
                        key={paymentInstrument?.id}
                        billingAddress={userAddress?.data}
                        paymentData={paymentInstrument}
                        billingText={billingText()}
                        updateBillingAddress={handleUpdateAddress}
                        setPaymentData={setPaymentInstrument}
                        isCreate={isCreate}
                        isOpen={!hasPayment} />}

                </Elements>
                {isContinuePlanMode && (
                    <div className={classNames(styles['change__promo-code'], styles['change__promo-code--bottom'])}>
                        <PromoCode setPromoCode={setPromoCode} setCouponInfo={setCouponInfo} packageId={planId} />
                    </div>
                )}
                <div className={styles['change__next-steps']}>
                    <h3>Next Steps</h3>
                    <div className={styles['change__next-steps-text']}> </div>
                </div>
                <div className={styles['change__account-buttons']}>
                    <button className={classNames(styles['change__submit'], 'account__button')} type="submit" aria-label="submit" onClick={handleSubmit} disabled={isSubmitButtonDisabled || previewPlanErrorMessage !== undefined}>
                        {isContinuePlanMode ? 'Resubscribe' : 'Confirm Changes'}
                    </button>
                    <button className="account__button-secondary" type="submit" aria-label="go back" onClick={router.back}>
                        Back
                    </button>
                </div>
                <ActionModal
                    title="Account Modification Unavailable"
                    subtitle={previewPlanErrorMessage}
                    confirmButtonText="Go Back"
                    hideCancel
                    onConfirm={() => {
                        router.back();
                    }}
                    onClose={() => {
                        setPreviewPlanErrorMessage(undefined);
                    }}
                    variant="light"
                    isOpen={previewPlanErrorMessage !== undefined}
                />
                <ActionModal
                    title="Attention"
                    subtitle={previewPlanConfirmMessage}
                    confirmButtonText="Ok"
                    onConfirm={() => {
                        setPreviewPlanConfirmMessage(undefined);
                    }}
                    onClose={() => {
                        router.back();
                    }}
                    variant="light"
                    isOpen={previewPlanConfirmMessage !== undefined}
                />
            </div>
        </>

    );
}

export default AccountPlanUpgradeV2;
/* eslint-enable @typescript-eslint/no-explicit-any */
