import { useCreateLike, useInfiniteSearchPresets, useInfiniteSearchSound, useLabelByName, useLabelNewestPacks, useLabelTopPacks } from '@bpm-web-app/swr-hooks';
import { appendQueryParams, createAppRoutes, useViewport, getMutatedSounds, useCreateFilterParams, getMutatedPresets, useUserSettings, CreateCardCarouselItem, rebuildReactTooltip } from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import { useMemo, useCallback, useContext, ComponentProps, useEffect, useState } from 'react';
import { Checkboxes, ExpandableText, GhostComponent, MissingImgPlaceholder } from '@bpm-web-app/components/shared';
import Filters from '../../filters/filters';
import { CreateCardCarousel } from '../../create-card-carousel/create-card-carousel';
import MediaDetailBanner from '../../shared/ui/media-detail-banner/media-detail-banner';
import TrackListCreate from '../../shared/track-list/create/track-list-create';
import styles from './label-detail.module.css';
import { FiltersContext } from '../../filters/filters.context';
import { SecondaryPageTitle } from '../../shared/secondary-page-title/secondary-page-title';
import { HorizontalTabs } from '../../shared/ui/horizontal-tabs/horizontal-tabs';
import Title from '../../title/title';
import { FollowButton } from '../../shared/follow-button/follow-button';
import { SquaredButton } from '../../shared/squared-button/squared-button';
import { ShareURL } from '../../shared/share-url/share-url';
import TrackListCreatePresets from '../../shared/track-list/create-presets/track-list-create-presets';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LabelDetailProps { }

export function LabelDetail(props: LabelDetailProps) {
    const router = useRouter();
    const { slug } = router.query;

    const { isMobile } = useViewport();
    const descriptionLineHeight = useMemo(() => (isMobile ? 32 : 16), [isMobile]);

    const { tags: filterTags, setTags } = useContext(FiltersContext);

    const [filterOptions, setFilterOptions] = useState<{
        label: string;
        value: string;
    }[]>([]);

    const query = useCreateFilterParams({ label: slug as string, });

    const { data, isLoading, error } = useLabelByName(slug as string);
    const { data: newestPacks, isLoading: isLoadingNewestPacks, error: errorNewestPacks } = useLabelNewestPacks(slug as string, query);

    const { data: topPacks, isLoading: isLoadingTopPacks, error: errorTopPacks } = useLabelTopPacks(slug as string, query);

    const { data: soundsData, error: errorSounds, setSize, isLastPage, isLoadingMore, mutate, isLoadingInitialData: isLoadingInitialSoundsData } = useInfiniteSearchSound(query);

    const { isAnonymous, setSelectedMedia, setShowSignUpModal } = useUserSettings();

    const setCurrentMediaInContext = useCallback(() => {
        if (isAnonymous && topPacks?.data) {
            const currentCenterMedia = topPacks?.data[0];
            if (currentCenterMedia) setSelectedMedia(currentCenterMedia as unknown as CreateCardCarouselItem, topPacks?.data as unknown as CreateCardCarouselItem[]);
        }
    }, [isAnonymous, topPacks?.data, setSelectedMedia]);

    useEffect(() => {
        if (isAnonymous) {
            setCurrentMediaInContext();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAnonymous]);

    const midiQuery = useMemo(() => {
        return {
            type: 'midi',
            ...query
        };
    }, [query]);

    const { data: midiData,
        error: errorMidi,
        setSize: setMidiSize,
        isLastPage: isLastMidiPage,
        isLoadingMore: isLoadingMoreMidi,
        mutate: mutateMidiInternal,
        isLoadingInitialData: isLoadingInitialMidiData
    } = useInfiniteSearchSound(midiQuery);

    const { data: presetData,
        setSize: setPresetSize,
        isLastPage: isLastPresetPage,
        isLoadingMore: isLoadingMorePreset,
        mutate: mutatePresetInternal,
        isLoadingInitialData: isLoadingInitialPresetData
    } = useInfiniteSearchPresets(query);

    const isEverythingLoaded = useMemo(() => {
        if (
            !isLoading &&
            !isLoadingNewestPacks &&
            !isLoadingTopPacks &&
            !isLoadingInitialSoundsData &&
            !isLoadingInitialMidiData &&
            !isLoadingInitialPresetData
        ) return true;
        return false;
    }, [isLoading, isLoadingInitialMidiData, isLoadingInitialSoundsData, isLoadingNewestPacks, isLoadingTopPacks, isLoadingInitialPresetData]);

    const mutateSound = useCallback(
        (id: string, progress: number) => {
            mutate(getMutatedSounds(soundsData, id, progress));
        },
        [mutate, soundsData]
    );

    const mutateMidi = useCallback(
        (id: string, progress: number) => {
            mutateMidiInternal(getMutatedSounds(midiData, id, progress));
        },
        [mutateMidiInternal, midiData]
    );

    const mutatePreset = useCallback(
        (id: string, progress: number) => {
            mutatePresetInternal(getMutatedPresets(presetData, id, progress));
        },
        [mutatePresetInternal, presetData]
    );

    useEffect(() => {
        rebuildReactTooltip();
    }, []);

    useEffect(() => {
        if (data?.top_tags) {
            const topTags: {
                label: string;
                value: string;
            }[] = [];
            data.top_tags.forEach((tag) => {
                topTags.push({ label: tag.name, value: tag.id });
            });
            setFilterOptions(topTags);
        }
    }, [data?.top_tags]);

    const sounds = useMemo(() => soundsData?.flatMap((d) => d?.data || []), [soundsData]);
    const midis = useMemo(() => midiData?.flatMap((d) => d?.data || []), [midiData]);
    const presets = useMemo(() => presetData?.flatMap((d) => d?.data || []), [presetData]);

    const totalSounds = useMemo(() => soundsData?.[0]?.pagination?.total || 0, [soundsData]);
    const totalMidis = useMemo(() => midiData?.[0]?.pagination?.total || 0, [midiData]);
    const totalPresets = useMemo(() => presetData?.[0]?.pagination?.total || 0, [presetData]);

    const handleLoadMore = useCallback(() => {
        if (!isLoadingMore && !isLastPage) {
            setSize((prevSize) => prevSize + 1);
        }
    }, [isLastPage, isLoadingMore, setSize]);

    const handleLoadMoreMidis = useCallback(() => {
        if (!isLoadingMoreMidi && !isLastMidiPage) {
            setMidiSize((prevSize) => prevSize + 1);
        }
    }, [isLastMidiPage, isLoadingMoreMidi, setMidiSize]);

    const handleLoadMorePreset = useCallback(() => {
        if (!isLoadingMorePreset && !isLastPresetPage) {
            setPresetSize((prevSize) => prevSize + 1);
        }
    }, [isLastPresetPage, isLoadingMorePreset, setPresetSize]);

    const { isLiked, likeDislike } = useCreateLike('label');
    const handleLikeLabel = useCallback(() => {
        if (isAnonymous) {
            setCurrentMediaInContext();
            setShowSignUpModal({ type: 'label' });
            return;
        }
        if (data?.id) {
            likeDislike(data.id);
            rebuildReactTooltip();
        }
    }, [isAnonymous, data?.id, setCurrentMediaInContext, setShowSignUpModal, likeDislike]);

    const soundTabs = useMemo<ComponentProps<typeof HorizontalTabs>['tabs']>(
        () => [
            {
                id: 'sounds',
                title: 'All',
                count: totalSounds || 0,
                activeTabComponent: (
                    <div className={styles['label-detail__track-list']}>
                        <TrackListCreate
                            mutateSound={mutateSound}
                            list={sounds}
                            onLoadMore={handleLoadMore}
                            isLoadingMore={isLoadingMore}
                            hideHeading={isMobile}
                            isLoading={!isEverythingLoaded}
                        />
                    </div>
                ),
            },
            {
                id: 'soundsMIDI',
                title: 'MIDI',
                count: totalMidis || 0,
                activeTabComponent: (
                    <div className={styles['label-detail__track-list']}>
                        <TrackListCreate
                            mutateSound={mutateMidi}
                            list={midis}
                            isLoadingMore={isLoadingMoreMidi}
                            onLoadMore={handleLoadMoreMidis}
                            hideHeading={isMobile}
                            isLoading={!isEverythingLoaded}
                        />
                    </div>
                ),
            },
            {
                id: 'presets',
                title: 'Presets',
                count: totalPresets || 0,
                activeTabComponent: (
                    <div className={styles['label-detail__track-list']}>
                        <TrackListCreatePresets
                            mutateSound={mutatePreset}
                            list={presets}
                            isLoadingMore={isLoadingMoreMidi}
                            onLoadMore={handleLoadMorePreset}
                            hideHeading={isMobile}
                            isLoading={!isEverythingLoaded}
                        />
                    </div>
                ),
            },
        ],
        // eslint-disable-next-line max-len
        [totalSounds, mutateSound, sounds, handleLoadMore, isLoadingMore, isMobile, isEverythingLoaded, totalMidis, mutateMidi, midis, isLoadingMoreMidi, handleLoadMoreMidis, totalPresets, mutatePreset, presets, handleLoadMorePreset]
    );

    return (
        <>
            <Title platform="create" title={data?.name || 'Loading Label...'} />
            <div>
                {isEverythingLoaded ? (
                    <MediaDetailBanner className={styles['label-detail__banner-container']}>
                        {{
                            image: (
                                <div className={styles['label-detail__img-container']}>
                                    {data?.logo_url ? (
                                        <picture>
                                            <source
                                                srcSet={`${appendQueryParams(data.logo_url, { key: 'dw', value: 296 })}, ${appendQueryParams(data.logo_url, { key: 'dw', value: 592 })} 2x`}
                                                media="(min-width: 1024px)"
                                            />
                                            <img
                                                src={appendQueryParams(data.logo_url, { key: 'dw', value: 296 })}
                                                srcSet={`${appendQueryParams(data.logo_url, { key: 'dw', value: 592 })} 2x`}
                                                alt={data.name}
                                                draggable={false}
                                            />
                                        </picture>
                                    ) : (
                                        <MissingImgPlaceholder />
                                    )}
                                </div>
                            ),
                            text: (
                                <>
                                    <div className={styles['label-detail__info']}>{`${data?.soundpackage_count} PACKS`}</div>

                                    <div className={styles['label-detail__title-container']}>
                                        <h2>{data?.name}</h2>
                                        <Filters platform="create" showOnMobile />
                                    </div>

                                    {data?.top_tags ? <Checkboxes
                                        options={filterOptions}
                                        value={filterTags}
                                        onChange={(values) => setTags(values)} />
                                        : null}

                                    <ExpandableText collapsedLinesCount={3} textLineHeight={descriptionLineHeight}>
                                        <div className={styles['label-detail__expandable-text']}>{data?.description}</div>
                                    </ExpandableText>

                                    <div className={styles['label-detail__info-container']}>
                                        <div className={styles['label-detail__info-buttons']}>
                                            <FollowButton isFollowed={isLiked(data?.id || '')} type="label" handleFollow={handleLikeLabel} />
                                            <SquaredButton label="See All Packs" type="outline" onPress={() => router.push(createAppRoutes.labelNewestPacks(slug as string))} />
                                        </div>
                                        <ShareURL url={window.location.href} />
                                    </div>
                                </>
                            ),
                        }}
                    </MediaDetailBanner>
                ) : <GhostComponent type="label-detail" />}

                <div>
                    <CreateCardCarousel
                        contentType="pack"
                        items={isLoadingTopPacks ? [] : topPacks?.data || []}
                        isLoading={!isEverythingLoaded}
                        cardSize="small"
                        carouselTitle="Top Packs"
                        carouselMorePath={createAppRoutes.labelTopPacks(`${slug}`)}
                    />

                    <CreateCardCarousel
                        contentType="pack"
                        items={isLoadingNewestPacks ? [] : newestPacks?.data || []}
                        isLoading={!isEverythingLoaded}
                        cardSize="small"
                        carouselTitle="New Packs"
                        carouselMorePath={createAppRoutes.labelNewestPacks(`${slug}`)}
                    />
                </div>
                <div className="spacing__window--horizontal">
                    <SecondaryPageTitle title="Sounds" noPadding />
                    <HorizontalTabs tabs={soundTabs} />
                </div>
            </div>
        </>
    );
}

export default LabelDetail;
