import { Album, Genre, Media, MediaWithAlbum } from '@bpm-web-app/stream-api-sdk';
import { Artist, Album as DownloadAlbum, MediaWithAlbum as DownloadMediaWithAlbum } from '@bpm-web-app/download-api-sdk';
import { v4 as uuid } from 'uuid';
import {
    Contest,
    ContestSubmissionSongUrl,
    ContestSubmissionSongWave,
    CuratedSet,
    Pagination,
    Preset,
    Sound,
    SoundPackage,
    SoundPackageDemoFileUrl,
    SoundPackageDemoWave,
} from '@bpm-web-app/create-api-sdk';
import { ReactNode } from 'react';

export interface SoundsResults {
    data: Array<Sound>;
    pagination: Pagination;
}

export interface PresetResults {
    data: Array<Preset>;
    pagination: Pagination;
}

export type SeeMoreItem = {
    id: string | number;
    title: string;
    description: ReactNode | string | Genre;
    image_url: string;
    slug?: string;
    label?: string;
    demo_file_url?: SoundPackageDemoFileUrl;
    demo_wave?: SoundPackageDemoWave;
    demo_file_url_2?: SoundPackageDemoFileUrl;
    demo_wave_2?: SoundPackageDemoWave;
    shareUrl?: string;
    badge?: SoundPackage.BadgeEnum;
};

export type CreatePlayerItem = {
    label?: string;
    artwork_url?: string;
    demo_file_url?: SoundPackageDemoFileUrl;
    demo_wave?: SoundPackageDemoWave;
    demo_file_url_2?: SoundPackageDemoFileUrl;
    demo_wave_2?: SoundPackageDemoWave;
    id: string;
    slug?: string;
    name: string;
    skipSilence: number;
    playSecondDemo?: boolean;
};

export type QueueItem = {
    /** Useful when we have duplicated songs in the queue */
    uuid: string;
    id: Media['id'];
    artist: Media['artist'];
    artists?: Media['artists'];
    bpm: Media['bpm'];
    cover_url: string;
    actual_key: string;
    display_key: string;
    genre: Genre;
    isExclusive?: boolean;
    stream_info: Media['stream_info'];
    title: string;
    waves: Media['waves'];
    estimated_duration?: string;
    album_id?: number;
    /** Only available to download API to display the number of versions */
    versions?: number;
    version?: string;
    /** For supreme maxi player queue */
    media?: Media[];
    artist_remixer?: Artist;
};

// Modern version of the Fisher–Yates shuffle algorithm
export function shufflePlaylist(list: Array<QueueItem>, currentTrack: QueueItem): Array<QueueItem> {
    const shuffledList = [];
    const incomingList = [...list].filter((track) => track.uuid !== currentTrack.uuid);

    while (incomingList.length) {
        const randomIndex = Math.floor(Math.random() * incomingList.length);
        const element = incomingList.splice(randomIndex, 1);
        shuffledList.push(element[0]);
    }

    return [currentTrack, ...shuffledList];
}

export function insertTrackOrTracks<T>(arr: T[], index: number, ...newItems: T[]): T[] {
    return [...arr.slice(0, index), ...newItems, ...arr.slice(index)];
}

export function generateUuid(): string {
    return uuid();
}

/**
 * This is an helper function to allow us to convert the `MediaWithAlbum`
 * to a `QueueItem`
 */
export const streamMediaWithAlbumToQueueItem = (media: MediaWithAlbum): QueueItem => ({
    ...media,
    bpm: media.bpm ?? 0,
    display_key: media.display_key ?? '',
    actual_key: media.key ?? '',
    cover_url: media.album?.cover_url || '',
    isExclusive: media.album?.is_exclusive,
    genre: media.album?.genre,
    uuid: generateUuid(),
});

/**
 * This is an helper function to allow us to know which properties need to be passed to
 * the `QueueItem` when the Album is not available
 */
export const streamMediaWithoutAlbumToQueueItem = (media: Media, otherMediaInfo: { cover_url: string; isExclusive: boolean; genre: Genre }): QueueItem => ({
    ...media,
    bpm: media.bpm ?? 0,
    display_key: media.display_key ?? '',
    actual_key: media.key ?? '',
    ...otherMediaInfo,
    uuid: generateUuid(),
});

/**
 * This is meant to be used with the download library
 * Helper function to allow us to extract the needed info from an album and append it to
 * the `QueueItem`.
 * By default this will extract the first media/version
 *
 * @param album the album to extract info `DownloadAlbum`
 * @param index the media/version index to return for the queue item, default value is 0
 */
export const downloadAlbumWithMediaToQueueItem = (album: DownloadAlbum, index = 0): QueueItem => {
    if (album.media && album.media.length - 1 >= index && index >= 0) {
        return {
            ...album.media[index],
            id: album.media[index].id,
            title: album.media[index].name,
            artist: album.artist,
            artists: album.artists,
            bpm: album.bpm || 0,
            display_key: album.display_key || '',
            actual_key: album.key ?? '',
            cover_url: album.cover_url,
            genre: album.genre,
            isExclusive: album.is_exclusive,
            uuid: generateUuid(),
            versions: album.media.length,
            version: album.media[index].version?.name,
            album_id: album.id,
            artist_remixer: album.artist_remixer,
            // TODO: fix these types
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            media: album.media,
        };
    }

    throw new Error('Invalid data passed to downloadMediaWithAlbumToQueueItem');
};

/**
 * This is meant to be used with the download library.
 * Helper function to allow us to extract the needed info from a media with album and
 * convert into a `QueueItem`.
 *
 * @param media the media to extract info `downloadComponents['schemas']['MediaWithAlbum']`
 */
export const downloadMediaWithAlbumToQueueItem = (media: DownloadMediaWithAlbum, numberOfVersions: number): QueueItem => {
    if (media.album) {
        const { album } = media;

        return {
            ...media,
            title: media.name,
            artist: album.artist,
            artists: album.artists,
            bpm: album.bpm || 0,
            display_key: album.display_key || '',
            actual_key: album.key ?? '',
            cover_url: album.cover_url,
            genre: album.genre,
            isExclusive: album.is_exclusive,
            uuid: generateUuid(),
            versions: numberOfVersions,
            version: media.version?.name,
            artist_remixer: album.artist_remixer,
            // TODO: fix these types
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            media: album.media,
        };
    }

    throw new Error('Invalid data passed to downloadMediaWithAlbumToQueueItem');
};

// Code copied from BPM current codebase to identify silence in the beginning
export const getStartSilence = (silence_info?: string): number => {
    if (!silence_info || silence_info === '') {
        return 0;
    }

    try {
        const first = silence_info.split('|')[0];
        const start = first.split('s=')[1].split(';')[0];
        if (parseFloat(start) === 0.0) {
            const end = first.split('e=')[1];
            if (!Number.isNaN(parseFloat(end))) {
                return parseFloat(end);
            }
        }
    } catch (error) {
        return 0;
    }
    return 0;
};

export const contestSongToCreatePlayable = (contest: Contest): CreatePlayerItem => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { title, artwork_url, song_url, song_wave } = contest;

    /* NOTE(paulomartinsbynd): because these contest demo don't have a
     * complete structure [we are just using its title, url and wave]
     * ID is being assigned as ? so we can later prevent 3 dots button rendering */

    return {
        artwork_url,
        demo_file_url: song_url,
        demo_wave: song_wave,
        id: '?',
        name: title,
        skipSilence: 0,
    };
};

export const dataToCreatePlayable = (
    id: string,
    name: string,
    demo_file_url: ContestSubmissionSongUrl,
    demo_wave: ContestSubmissionSongWave,
    slug: string,
    artwork_url?: string,
    label?: string
): CreatePlayerItem => ({
    demo_file_url,
    demo_wave,
    id,
    name,
    skipSilence: 0,
    label,
    artwork_url,
    slug,
});

export const soundToCreatePlayable = (sound: Sound): CreatePlayerItem => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { id, name, artwork_url, file_url, wave, silence_info } = sound;

    return {
        artwork_url,
        demo_file_url: file_url,
        demo_wave: wave,
        id,
        name,
        skipSilence: getStartSilence(silence_info),
    };
};

export const curatedPackToCreatePlayable = (curatedSet: CuratedSet): CreatePlayerItem => {
    const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        id, name, artwork_url, demo_file_url, demo_wave, slug,
    } = curatedSet;

    return {
        artwork_url,
        demo_file_url,
        demo_wave,
        id,
        slug,
        name,
        skipSilence: 0,
    };
};

export const soundPackageToCreatePlayable = (pack: SoundPackage): CreatePlayerItem => {
    const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        id, slug, name, Label, artwork_url, demo_file_url, demo_wave, demo_file_url_2, demo_wave_2,
    } = pack;

    return {
        label: Label.name,
        artwork_url,
        demo_file_url,
        demo_wave,
        demo_file_url_2,
        demo_wave_2,
        id,
        slug,
        name,
        skipSilence: 0,
    };
};

export const seeMoreItemToCreatePlayable = (item: SeeMoreItem): CreatePlayerItem => {
    const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        id, slug, title, label, image_url, demo_file_url, demo_wave, demo_file_url_2, demo_wave_2,
    } = item;

    return {
        label,
        artwork_url: image_url,
        demo_file_url,
        demo_wave,
        demo_file_url_2,
        demo_wave_2,
        id: String(id),
        slug,
        name: title,
        skipSilence: 0,
    };
};

export const getMutatedSoundsNotPaginated = (soundsData: SoundsResults, id: string, progress: number): SoundsResults => ({
    data: soundsData.data.map((sound: Sound) => (sound.id === id ? { ...sound, streamed: progress } : sound)),
    pagination: soundsData.pagination,
});

export const getMutatedSounds = (soundsData: SoundsResults[], id: string, progress: number): SoundsResults[] => soundsData?.map((data) => ({
    data: data.data.map((sound: Sound) => (sound.id === id ? { ...sound, streamed: progress } : sound)),
    pagination: data.pagination,
}));

export const getMutatedPresetsNotPaginated = (soundsData: PresetResults, id: string, progress: number): PresetResults => ({
    data: soundsData.data.map((sound: Preset) => (sound.id === id ? { ...sound, streamed: progress } : sound)),
    pagination: soundsData.pagination,
});

export const getMutatedPresets = (presetData: PresetResults[], id: string, progress: number): PresetResults[] => presetData?.map((data) => ({
    data: data.data.map((sound: Preset) => (sound.id === id ? { ...sound, streamed: progress } : sound)),
    pagination: data.pagination,
}));
